import {
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ActionIcon, Progress, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { IconDownload, IconEdit, IconRefresh } from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableInstance,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import {
  getAccountReceivableData,
  refreshARReport,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { TFilterAccountPayable } from 'src/Redux/Broker/Reports/APAgingSummary/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import { getCSVConfigByReportName } from 'src/utils/reports/generateAllReports';
import { formatNumber } from '../MantineTable';
import EditAccountReceivable from './components/EditAccountReceivable';
import EditAccountReceivableADAModal from './components/EditAccountReceivableADAModal';

interface APTable {
  tableData: Record<string, any>[] | undefined;
  filterType: TFilterAccountPayable;
  isInitialLoading: boolean;
  selectApplicantData: Record<string, any>;
  applicantId: string;
  ADAData: Record<string, any>;
}

interface TAccountReceivableRow {
  AccountPayableId?: number;
  Customer: string;
  Current: string;
  '1 - 30': string;
  '31 - 60': string;
  '61 - 90': string;
  '91 and over': string;
  Total: string;
  question?: string | null;
  holdbackRetention?: number | null;
  retentionValue?: number | null;
  applyADA?: string | null;
}

const AccountReceivableTable = ({
  tableData,
  filterType,
  isInitialLoading,
  selectApplicantData,
  applicantId,
  ADAData,
}: APTable) => {
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [opened, { open, close }] = useDisclosure();
  const [isOpenADA, { open: openADA, close: closeADA }] = useDisclosure();
  const [selectedEditRow, setSelectedEditRow] = useState<{
    row: MRT_Row<Record<string, any>> | null;
    table: MRT_TableInstance<Record<string, any>> | null;
  }>({
    row: null,
    table: null,
  });
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const toast = useAppToast();

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'REFRESH_AR_REPORT') {
          setIsRefresh(false);
          dispatch(
            getAccountReceivableData({
              applicantId: Number(applicantId),
              filter: filterType,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (accountPayableReport.type === 'REFRESH_AR_REPORT') {
          setIsRefresh(true);
        }
        return;
      }
      case 'failed': {
        if (accountPayableReport.type === 'REFRESH_AR_REPORT') {
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          setIsRefresh(false);
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  const columns = useMemo<MRT_ColumnDef<TAccountReceivableRow>[]>(
    () => [
      {
        accessorKey: 'Customer',
        header: 'Customer',
      },
      {
        accessorKey: 'Current',
        header: 'Current',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: '1 - 30',
        header: '1 - 30',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: '31 - 60',
        header: '31 - 60',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: '61 - 90',
        header: '61 - 90',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: '91 and over',
        header: '91 and over',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: 'Total',
        header: 'Total',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
    ],
    [filterType]
  );

  const getTableBodyRowProps = ({ row }: { row: MRT_Row }) => {
    if (tableData) {
      const isLastRow = row.index === tableData?.length - 1;

      return {
        style: {
          fontWeight: isLastRow ? 'bold' : 'normal',
        },
      };
    } else return {};
  };

  const checkIsPositive = (row: Record<string, any>) => {
    return (
      row?.['91 and over'] !== '' &&
      Math.sign(Number(row?.['91 and over'])) === 1
    );
  };

  const checkTotalInclude = (row: Record<string, any>) => {
    return row?.Customer?.toLowerCase().includes('total');
  };

  const checkADAInclude = (row: Record<string, any>) => {
    return row?.Customer?.includes('Allowance Doubtful Account (ADA)');
  };

  const getRetionValue = (row: Record<string, any>) => {
    return row?.retentionValue !== ''
      ? formatNumber(Number(row?.retentionValue))
      : row?.retentionValue;
  };

  const renderEditRowAction = ({
    row,
    table,
  }: {
    row: MRT_Row;
    table: MRT_TableInstance;
  }) => {
    const isPositiveValue = checkIsPositive(row?.original);
    const isTotal = checkTotalInclude(row?.original);
    const isADARow = checkADAInclude(row?.original);
    console.log('ttotal', isTotal, row?.original, isADARow);

    if (isPositiveValue && !isTotal && !isADARow) {
      return (
        <Flex gap={3} justifyContent={'left'}>
          <ActionIcon
            onClick={() => {
              // table.setEditingRow(row);
              console.log('row', row);

              setSelectedEditRow({
                row: row,
                table: table,
              });
              open();
            }}
            size={'sm'}
          >
            <Tooltip label="Edit" position="right">
              <IconEdit />
            </Tooltip>
          </ActionIcon>
          <Box>{getRetionValue(row?.original)}</Box>
        </Flex>
      );
    } else if (isADARow) {
      return (
        <Flex gap={2} justifyContent={'left'}>
          <ActionIcon
            onClick={() => {
              // table.setEditingRow(row);
              console.log('row', row);

              setSelectedEditRow({
                row: row,
                table: table,
              });
              openADA();
            }}
            size={'sm'}
          >
            <Tooltip label="Edit" position="right">
              <IconEdit />
            </Tooltip>
          </ActionIcon>
          <ChakraTooltip
            fontSize="md"
            label="Specify the amount that might not be collectible for each time interval of your accounts receivable"
            placement="auto"
          >
            <Box>
              <HiMiniInformationCircle size={22} />
            </Box>
          </ChakraTooltip>
          {/* <Box>{getRetionValue(row?.original)}</Box> */}
        </Flex>
      );
    }
  };

  const replaceNullUndefined = (obj: Record<string, any>) => {
    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = Array.isArray(obj) ? [] : {};

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          // Recursively call replaceNullUndefined for each element
          newObj[i] = replaceNullUndefined(obj[i]);
        }
      } else {
        // If obj is an object, iterate through its properties
        for (let key in obj) {
          // Recursively call replaceNullUndefined for each property value
          newObj[key] = replaceNullUndefined(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj === null || obj === undefined ? '' : obj;
    }
  };

  const handleExportData = (...data: any) => {
    // const csvConfig = mkConfig({
    //   fieldSeparator: ',',
    //   decimalSeparator: '.',
    //   // useKeysAsHeaders: true,
    //   filename: `${selectApplicantData?.companyName} Account Receivable Aging Report`,
    //   replaceUndefinedWith: '',
    //   showTitle: true,
    //   showColumnHeaders: true,
    //   columnHeaders: [
    //     ...columns,
    //     { accessorKey: 'retentionValue', header: 'Holdback / Retention' },
    //   ].map((column) => ({
    //     key: column.accessorKey as string,
    //     displayLabel: column.header,
    //   })),
    //   // title: `Account Receivable Aging report of ${selectApplicantData?.companyName} for ${filterType} as on ${accountPayableReport?.data?.reportInfo ? accountPayableReport?.data?.reportInfo?.reportGeneratedDate : new Date()}`,
    //   title: `${accountPayableReport?.data?.reportInfo?.qboCompanyName} \r\n ${accountPayableReport?.data?.reportInfo?.reportName} Summary \r\n As of ${accountPayableReport?.data?.reportInfo ? accountPayableReport?.data?.reportInfo?.reportGeneratedDate : new Date()} \r\n`,
    // });
    const config = getCSVConfigByReportName({
      type: 'Account Receivable Aging',
      title: `${accountPayableReport?.data?.reportInfo?.qboCompanyName} \r\n${accountPayableReport?.data?.reportInfo?.reportName} Summary \r\n${filterType} \r\nAccounting data last updated at ${accountPayableReport?.data?.lastUpdateAt ? accountPayableReport?.data?.lastUpdateAt : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
      filename: `${selectApplicantData?.companyName} Account Receivable Aging Report`,
    });
    const csvConfig = mkConfig(config);

    const refineData = tableData?.map((element: Record<string, any>) => {
      const isPositiveValue = checkIsPositive(element);
      const isTotal = checkTotalInclude(element);
      const isADARow = checkADAInclude(element);
      let updateElement = {
        ...element,
        retentionValue:
          isPositiveValue && !isTotal && !isADARow
            ? element?.retentionValue
              ? parseFloat(element?.retentionValue?.toFixed(2))
              : 0
            : null,
      };
      return replaceNullUndefined(updateElement);
    });

    if (refineData && refineData.length > 0) {
      const csv = generateCsv(csvConfig)(refineData);
      download(csvConfig)(csv);
    }
  };

  const table = useMantineReactTable({
    columns: columns as any,
    data: (tableData ?? []) as any,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 50, maxSize: 50, size: 100 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: isInitialLoading ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'capital-assets-table',
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },

    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    mantineTableBodyRowProps: getTableBodyRowProps,
    enableColumnPinning: true,
    enablePagination: false,
    positionPagination: 'none',
    enableStickyHeader: false,
    enableBottomToolbar: false,
    enableRowActions: true,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        // header: 'Holdback / Retention (S)', //change header text
        Header: () => {
          return (
            <Flex gap={2}>
              <Text>Holdback / Retention</Text>
              <ChakraTooltip
                fontSize="md"
                label={`Provide the holdback/retention amount of your Accounts Receivable for the period labeled '91 days and over'`}
                placement="auto"
              >
                <Box>
                  <HiMiniInformationCircle size={22} />
                </Box>
              </ChakraTooltip>
            </Flex>
          );
        },
        size: 300, //make actions column wider
      },
    },
    positionActionsColumn: 'last',
    renderRowActions: renderEditRowAction,
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              color="lightblue"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={(...data) => handleExportData(data)}
              leftIcon={<IconDownload />}
              variant="filled"
              className="primary-btn"
            >
              Export All Data
            </Button>
            {accountPayableReport?.data && (
              <Flex flexDirection={'column'}>
                <Flex alignItems={'flex-start'} gap={1}>
                  <ActionIcon
                    style={{ color: '#114684' }}
                    onClick={() =>
                      dispatch(
                        refreshARReport({
                          applicantId: applicantId,
                          filter: filterType,
                        })
                      )
                    }
                    size={'sm'}
                  >
                    <Tooltip
                      label={'Refresh Data'}
                      bg="gray.300"
                      color="black"
                      position="right"
                    >
                      <IconRefresh />
                    </Tooltip>
                  </ActionIcon>
                  <Text fontSize={'smaller'}>
                    Accounting data last updated at
                  </Text>
                  <Text fontSize={'smaller'}>
                    {accountPayableReport?.data?.lastUpdateAt}
                  </Text>
                </Flex>
                <Text>
                  <span>A/R Aging Summary as of&nbsp;</span>
                  {/* <Tooltip
                      label={'Start Date'}
                      bg="gray.300"
                      color="black"
                      position="top"
                    >
                      <span>
                        {`${moment(accountPayableReport?.data?.reportInfo?.startPeriod).format('MMMM DD, YYYY')} -`}
                        &nbsp;
                      </span>
                    </Tooltip> */}
                  <Tooltip
                    label={'End Date'}
                    bg="gray.300"
                    color="black"
                    position="right"
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                    >{`${moment(accountPayableReport?.data?.reportInfo?.endPeriod).format('MMMM DD, YYYY')}`}</span>
                  </Tooltip>
                </Text>
              </Flex>
            )}
          </Box>
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            {/* <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box> */}
          </Flex>
        </Flex>
        {isRefresh && <Progress value={100} animate={true} />}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isFullScreen: isFullScreen,
      isLoading: isInitialLoading || isRefresh,
    },
  });
  return (
    <>
      <ModalsProvider>
        <Box>
          <MantineReactTable table={table} key={'account_receivable'} />
        </Box>
        {opened && (
          <EditAccountReceivable
            table={selectedEditRow?.table}
            row={selectedEditRow?.row}
            modalOpen={opened}
            onClose={close}
          />
        )}
        {isOpenADA && (
          <EditAccountReceivableADAModal
            table={selectedEditRow?.table}
            row={selectedEditRow?.row}
            modalOpen={isOpenADA}
            onClose={closeADA}
            parentTableData={tableData!}
            ADAData={ADAData}
          />
        )}
      </ModalsProvider>
    </>
  );
};

export default AccountReceivableTable;
