import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import ApplicantDashboardTable from 'src/Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardTable';
import BiilingPage from 'src/Pages/BrokeragePages/Billing';
import ThreeTierPricing from 'src/Pages/BrokeragePages/Plans';
import Checkout from 'src/Pages/BrokeragePages/Plans/components/Checkout';
import BrokerApplicantProfile from 'src/Pages/BrokerPages/BrokerApplicantProfile';
import BrokerDashboardTable from 'src/Pages/BrokerPages/BrokerDashboard/BrokerDashboardTable';
import Help from 'src/Pages/BrokerPages/Help';
import APAgingSummary from 'src/Pages/Reports/APAgingSummary';
import NotFoundPage from '../Component/Common/404';
import Layout from '../Component/Common/Sidebar/Layout';
import SocketEvents from '../Component/Common/SocketEvents';
import ForgetEmail from '../Component/ForgotPassword/ForgotEmail';
import ForgetPassword from '../Component/ForgotPassword/ForgotPassword';
import Login from '../Component/Login/Login';
import ApplicantAccount from '../Component/SignUp/ApplicantAccount';
import BrokerAccount from '../Component/SignUp/BrokerAccount';
import BrokererageAccount from '../Component/SignUp/BrokerageAccount';
import FullScreenLayout from '../Layout/FullScreenLayout';
import AccountantDashboard from '../Pages/Account/AccountantDashboard';
import AccountantSignupLink from '../Pages/Account/AccountantSignupLink';
import AccountTabel from '../Pages/ApplicantPages/Accountant/AccountantTabel';
import ApplicantAccountant from '../Pages/ApplicantPages/AppliantAccountant/ApplicantAccountant';
import ApplicantBroker from '../Pages/ApplicantPages/ApplicantBrokerage/ApplicantBrokerage';
import ApplicantAwaitingUnderwriting from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/ApplicantAwaitingUnderwriting';
import ApplicantUnderwriting from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/ApplicantUnderwriting';
import Drafts from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/Drafts';
import ApplicantProfile from '../Pages/ApplicantPages/ApplicantProfile/ApplicantProfile';
import ApplicantQBO from '../Pages/ApplicantPages/ApplicantQBO/ApplicantQBO';
import QboConnect from '../Pages/ApplicantPages/ApplicantQBO/QboConnect';
import QboSuccess from '../Pages/ApplicantPages/ApplicantQBO/QboSuccess';
import QuickbookAlldata from '../Pages/ApplicantPages/ApplicantQBO/QuickbookAlldata';
import OwnerTabel from '../Pages/ApplicantPages/Owner/OwnerTabel';
import BrokerageCLform from '../Pages/BrokeragePages/BrokerageEnterCl/BrokerageCLform';
import BrokererageEnterCl from '../Pages/BrokeragePages/BrokerageEnterCl/BrokerageEnterCl';
import BrokererageEnterEAndO from '../Pages/BrokeragePages/BrokerageEnterEAndO/BrokerageEnterEAndO';
import BrokererageManagment from '../Pages/BrokeragePages/BrokerageManagment/BrokerageManagment';
import BrokerageProfile from '../Pages/BrokeragePages/Brokerageprofile/BrokerageProfile';
import BrokerAccessRequest from '../Pages/BrokerPages/BrokerAccessRequest';
import ApplicantAwaitingUnderwritingBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/ApplicantAwaitingUnderwriting';
import ApplicantUnderwritingBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/ApplicantUnderwriting';
import ApprovedBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Approved';
import DeclinedBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Declined';
import DraftsBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Drafts';
import GrantingPermission from '../Pages/BrokerPages/BrokerDashboard/Permission/GrantingPermission';
import Permission from '../Pages/BrokerPages/BrokerDashboard/Permission/Permission';
import PermissionVerify from '../Pages/BrokerPages/BrokerDashboard/Permission/PermissionVerify';
import BrokerProfile from '../Pages/BrokerPages/BrokerProfile/BrokerProfile';
import Stepper from '../Pages/BrokerPages/CreateApplicationForm/Stepper';
import OwnerPersonalFinanceForm from '../Pages/OwnerPages/OwnerPersonalFinanceForm';
import ReportWorkingCapital from '../Pages/Reports/WorkingCapital';
import ReportWorkingProgress from '../Pages/Reports/WorkingProgress';
import Upload from '../Pages/Upload';

interface RoutingProps {
  token: string | null;
  userType: string | null;
}

function Routing({ token, userType }: RoutingProps) {
  const userTypeLatest = localStorage.getItem('userType');
  const tokenLatest = localStorage.getItem('Token');

  let userResponse = userType || userTypeLatest;
  let tokenResponse = token || tokenLatest;

  const PrivateRoute = ({ children }: any) => {
    return tokenResponse ? (
      <SocketEvents>{children}</SocketEvents>
    ) : (
      <Navigate to="/login" />
    );
  };

  return (
    <Router>
      <Routes>
        <>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/pdf" element={<PDFView />} /> */}
          {/* <Route
            path="/temp"
            element={
              <PDFViewer width="100%" height="1500" className="app">
                <ApplicationPDF
                  title={'title'}
                  heading={'Heading'}
                  summary={
                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
                  }
                  logo={
                    ''
                    // 'https://source.unsplash.com/random/100x100/?company'
                  }
                  companyName="Company Name"
                  qboCompanyName="test123"
                  contactInfo={true}
                  brokerInfo={{
                    firstName: 'fname',
                    lastName: 'lname',
                    contactNumber: 1234567890,
                    brokerageName: 'brokerage name',
                    email: 'test@yopmail.com',
                  }}
                  allFormData={{
                    accountingSystem: {
                      applicationId: 27,
                      brokerId: 22,
                      applicantId: 73,
                      applicationName: 'a3195',
                      legalName: '',
                      businessEstablishedDate: '2024-05-21T11:12:44.000Z',
                      email: 'test@yopmail.com',
                      website: 'https://www.google.com/',
                      street: 'surat',
                      city: 'surat',
                      country: 'United States',
                      province: 'aa1',
                      postalCode: '454343',
                      mailingAddressSameAsOffice: false,
                      mailingStreet: 'testt',
                      mailingCity: 'surat',
                      mailingCountry: 'United States',
                      mailingProvince: 'gujarat',
                      mailingPostalCode: '12345',
                      telephone: '+1-212-456-7897',
                      accountingFirmName: 'test firm',
                      contactName: 'Macon Hoffman',
                      phoneNumber: null,
                      interimStatementInterval: 'annually',
                      grossProfitAtRecentFiscalYearEnd: null,
                      dateCompleted: '2024-05-06T11:13:47.000Z',
                      bankInfo: [
                        {
                          address:
                            'Suite 1500, 4710 Kingsway, Burnaby, BC, V5H4M2',
                          bankName: 'MERIDIAN ONECAP CREDIT CORP.',
                          securedVal: null,
                          creditSecured: null,
                          bankPhoneNumber: null,
                          otherSecuredVal: null,
                          usedLineOfCredit: null,
                          withBankSinceYear: null,
                          accountManagerName: null,
                          authorizedLineOfCredit: 0,
                        },
                        {
                          address:
                            '20 KING STREET W-4TH FLOOR, TORONTO, ON, M5H1C4',
                          bankName: 'ROYAL BANK OF CANADA',
                          securedVal: 'other',
                          creditSecured: true,
                          bankPhoneNumber: null,
                          otherSecuredVal: 'test other',
                          usedLineOfCredit: null,
                          withBankSinceYear: null,
                          accountManagerName: null,
                          authorizedLineOfCredit: 0,
                        },
                        {
                          address:
                            '20 KING STREET WEST - 4TH FLOOR, TORONTO, ON, M5H1C4',
                          bankName: 'ROYAL BANK OF CANADA',
                          securedVal: 'inventory',
                          creditSecured: true,
                          bankPhoneNumber: null,
                          otherSecuredVal: null,
                          usedLineOfCredit: null,
                          withBankSinceYear: null,
                          accountManagerName: null,
                          authorizedLineOfCredit: 0,
                        },
                        {
                          address: '',
                          bankName: '',
                          securedVal: '',
                          creditSecured: null,
                          bankPhoneNumber: null,
                          otherSecuredVal: null,
                          usedLineOfCredit: null,
                          withBankSinceYear: null,
                          accountManagerName: null,
                          authorizedLineOfCredit: 0,
                        },
                      ],
                      bankName: null,
                      address: null,
                      authorizedLineOfCredit: null,
                      accountManagerName: null,
                      bankPhoneNumber: null,
                      withBankSinceYear: null,
                      usedLineOfCredit: null,
                      assignToApplicant: true,
                      createdAt: '2024-05-20T11:12:31.000Z',
                      updatedAt: '2024-05-20T11:14:37.000Z',
                    },
                    creditBureau: {
                      bureauId: 27,
                      brokerId: 22,
                      applicationId: 27,
                      applicationName: 'a3195',
                      hasBankruptcyHistory: {
                        bankruptcyDetails: [
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '70',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '71',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '72',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '73',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '74',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '75',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '76',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: '24-10721',
                            trusteeName: null,
                            dateOfFiling: '2024-04-08',
                            filingStatus: 'Open',
                            creditReports: null,
                            dateOfDischarge: '2/4/2011',
                            totalDebtAmount: '77',
                            complianceStatus: null,
                            bankruptcyChapter: '11',
                            jurisdictionCourt:
                              'US Bankruptcy Court ,Wilmington ,United States',
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy:
                              'Voluntary Petition for Bankruptcy',
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                        ],
                        insolvencyDetails: [
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName: null,
                            dateOfFiling: '2019-04-03',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: null,
                            totalDebtAmount: '70',
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName:
                              'Ernst & Young Inc, Alex Morrison, Toronto, Ontario 416-941-7743',
                            dateOfFiling: '2019-04-03',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: '21/5/2024',
                            totalDebtAmount: null,
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                        ],
                        liquidationDetails: [
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName: null,
                            dateOfFiling: '2019-04-04',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: '3/4/2024',
                            totalDebtAmount: '11',
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName: null,
                            dateOfFiling: '2019-04-05',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: '2/2/2024',
                            totalDebtAmount: '12',
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName: null,
                            dateOfFiling: '2019-04-06',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: '2/3/2024',
                            totalDebtAmount: '13',
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                          {
                            outcome: null,
                            caseNumber: null,
                            trusteeName: null,
                            dateOfFiling: '2019-04-07',
                            filingStatus: null,
                            creditReports: null,
                            dateOfDischarge: '2/4/2024',
                            totalDebtAmount: '14',
                            complianceStatus: null,
                            bankruptcyChapter: null,
                            jurisdictionCourt: null,
                            legalDocumentation: null,
                            dischargeConditions: null,
                            reasonForBankruptcy: null,
                            assetsAtTimeOfFiling: null,
                            listOfMajorCreditors: null,
                            durationOfProceedings: null,
                            currentFinancialStatements: null,
                            impactOnBusinessOperations: null,
                            paymentHistoryPostBankruptcy: null,
                          },
                        ],
                      },
                      hasLiensFiledAgainstCompany: [
                        {
                          uuid: 'e0ddfa50-2094-4690-973b-33e0f6202670',
                          caseNumber: 'U240031431929',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: null,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: null,
                          nameOfLienHolder: [],
                        },
                        {
                          uuid: 'e0ddfa50-2094-4690-973b-33e0f6202671',
                          caseNumber: 'U240031431929',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: 316989,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: 'test reason',
                          nameOfLienHolder: [
                            '- CM-529769 - CARLOS HUBBARD ROSA OSEGUEDA',
                          ],
                          courtFilings: [
                            {
                              linkId: '7bit',
                              link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/sampleTest.pdf-1728550012834?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241010%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241010T084652Z&X-Amz-Expires=604800&X-Amz-Signature=719aa9fa36327f35e0b97971b25221be732d4d4386a12114e8a6977c2a7a0279&X-Amz-SignedHeaders=host&x-id=GetObject',
                              urlKey:
                                'applicant/legal/sampleTest.pdf-1728550012834',
                            },
                          ],
                        },
                      ],
                      hasStateFederalTax: [
                        {
                          uuid: '05152ed2-0a58-4a8a-8152-b5acc182e89e',
                          caseNumber: 'RP-2019-344656',
                          lienStatus: 'Open',
                          lienDetails: 'Tax Lien',
                          dateOfFiling: '2019-08-07',
                          filingAmount: {
                            value: 3133,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'HARRIS COUNTY RECORDERS OFFICE ,HOUSTON ,United States',
                          nameOfDebtor: ['99 CENTS ONLY STORES TEXAS, INC.'],
                          reasonForLien: 'Unemployment Contribution',
                          nameOfLienHolder: [
                            'TEXAS WORKFORCE COMMISSION',
                            'STATE OF TEXAS',
                          ],
                          hasPaymentPlan: false,
                        },
                        {
                          uuid: 'dd01b29d-4d69-4963-97da-4808370c6f57',
                          caseNumber: '17-0875625',
                          lienStatus: 'Release',
                          lienDetails: 'Tax Lien',
                          dateOfFiling: '2017-08-03',
                          filingAmount: {
                            value: 256,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'LOS ANGELES COUNTY RECORDER OF DEEDS ,NORWALK ,United States',
                          nameOfDebtor: [
                            '99 CENTS ONLY STORES LLC',
                            'BARGAIN WHOLESALE',
                          ],
                          reasonForLien: null,
                          nameOfLienHolder: [
                            'CA EMPLOYMENT DEVELOPMENT DEPARTMENT',
                          ],
                          hasPaymentPlan: true,
                          paymentPlanUpload: [
                            {
                              linkId: '7bit',
                              link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/sampleTest.pdf-1728554090691?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241010%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241010T095450Z&X-Amz-Expires=604800&X-Amz-Signature=93282724c43b1e080c87ac1a757f51c9fb8427fb1de604b6d9e17285914a6e88&X-Amz-SignedHeaders=host&x-id=GetObject',
                              urlKey:
                                'applicant/legal/sampleTest.pdf-1728554090691',
                            },
                          ],
                          courtFilings: [
                            {
                              linkId: '7bit',
                              link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/sample.pdf-1728554151839?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241010%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241010T095551Z&X-Amz-Expires=604800&X-Amz-Signature=66582dfad391c9200cd049bbe7ba784231633da026185b978218be3f81b8dff7&X-Amz-SignedHeaders=host&x-id=GetObject',
                              urlKey:
                                'applicant/legal/sample.pdf-1728554151839',
                            },
                          ],
                        },
                        {
                          uuid: '8db26c29-ec8d-40e2-ad32-21cba97ede74',
                          caseNumber: '0216137329',
                          lienStatus: 'Release',
                          lienDetails: 'Tax Lien',
                          dateOfFiling: '2016-10-05',
                          filingAmount: {
                            value: 95,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'KERN COUNTY RECORDERS OFFICE ,BAKERSFIELD ,United States',
                          nameOfDebtor: [
                            'ALDAYLAM SALEH ALI BIN ALI',
                            '99 CENT STORE',
                          ],
                          reasonForLien: null,
                          nameOfLienHolder: ['TAX COLLECTOR'],
                        },
                      ],
                      hasOutstandingJudgments: [
                        {
                          uuid: '3ba59878-9592-442c-8639-40c4938db2b7',
                          caseNumber: '17-0563258',
                          dateOfFiling: '2017-12-04',
                          filingAmount: null,
                          jurisdiction:
                            'SAN DIEGO COUNTY RECORDERS OFFICE ,SAN DIEGO ,United States',
                          currentStatus: 'Satisfied',
                          nameOfDebtors: [
                            '99 CENTS ONLY STORES LLC, A CALIFORNIA LIMITED LIA',
                          ],
                          natureOfClaim: null,
                          nameOfCreditors: ['DIANA SALAZAR'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: '74aeb728-e556-494d-b4d1-2934e2e699ad',
                          caseNumber: '17-0247990',
                          dateOfFiling: '2017-06-02',
                          filingAmount: null,
                          jurisdiction:
                            'SAN DIEGO COUNTY RECORDERS OFFICE ,SAN DIEGO ,United States',
                          currentStatus: 'Satisfied',
                          nameOfDebtors: [
                            '99 CENTS ONLY STORES LLC, A CALIFORNIA LIMITED LIA',
                          ],
                          natureOfClaim: null,
                          nameOfCreditors: ['BAHER MOHAMED'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: 'e44a3623-1e9e-4c56-8531-dba00968f21d',
                          caseNumber: '2015780889HNB',
                          dateOfFiling: '2015-08-10',
                          filingAmount: {
                            value: 2500,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'ORANGE COUNTY SMALL CLAIMS COURT/NEWPORT BEACH ,NEWPORT BEACH ,United States',
                          currentStatus: 'Unsatisfied',
                          nameOfDebtors: ['99 CENTS ONLY STORE'],
                          natureOfClaim: null,
                          nameOfCreditors: ['DORA GONZALEZ'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: '4a81d199-5903-4a71-b5cf-0dee92de568b',
                          caseNumber: '2014727331HNB',
                          dateOfFiling: '2014-09-29',
                          filingAmount: {
                            value: 1750,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'ORANGE COUNTY SMALL CLAIMS COURT/NEWPORT BEACH ,NEWPORT BEACH ,United States',
                          currentStatus: 'Unsatisfied',
                          nameOfDebtors: ['99 CENTS ONLY STORES'],
                          natureOfClaim: null,
                          nameOfCreditors: ['DANIELLE BEECH'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: 'c69619cd-6cd4-4bb4-af67-6b8c98c171e9',
                          caseNumber: '14CL28',
                          dateOfFiling: '2014-02-26',
                          filingAmount: {
                            value: 5000,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'SHASTA COUNTY SMALL CLAIMS COURT/REDDING ,REDDING ,United States',
                          currentStatus: 'Satisfied',
                          nameOfDebtors: ['99 CENT ONLY STORE'],
                          natureOfClaim: null,
                          nameOfCreditors: ['LYONS, ALICIA'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: 'cf2565b6-095d-4fcd-9cae-10b384c7a60e',
                          caseNumber: '2012573677CJC',
                          dateOfFiling: '2012-08-01',
                          filingAmount: {
                            value: 5443,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'ORANGE COUNTY SMALL CLAIMS COURT/SANTA ANA ,SANTA ANA ,United States',
                          currentStatus: 'Vacate',
                          nameOfDebtors: ['99 CENT STORES'],
                          natureOfClaim: null,
                          nameOfCreditors: ['GABRIELA MARTINEZ'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: '19a1d74d-a3ad-41a5-afa6-213d346db327',
                          caseNumber: '0808180834',
                          dateOfFiling: '2008-08-18',
                          filingAmount: {
                            value: 1191,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'SACRAMENTO COUNTY RECORDERS OFFICE ,SACRAMENTO ,United States',
                          currentStatus: 'Satisfied',
                          nameOfDebtors: [
                            '99 CENTS ONLY STORE A CALIFORNIA CORPORATION',
                          ],
                          natureOfClaim: null,
                          nameOfCreditors: [
                            'LABOR COMMISSIONER OF THE STATE OF CALIFORNIA',
                          ],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: 'e65b9c2b-0435-4c94-91c0-3fd48e91c955',
                          caseNumber: '03M07522',
                          dateOfFiling: '2003-06-17',
                          filingAmount: null,
                          jurisdiction:
                            'LOS ANGELES COUNTY SMALL CLAIMS COURT/LOS ANGELES ,LOS ANGELES ,United States',
                          currentStatus: 'Archived',
                          nameOfDebtors: [
                            '99 CENTS ONLY STORES, INC.',
                            '99 ONLY STORES',
                          ],
                          natureOfClaim: null,
                          nameOfCreditors: ['NETES, LEONID'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: '7bc11c9e-11d1-4316-b68c-2679079d1d88',
                          caseNumber: '01S02770',
                          dateOfFiling: '2002-06-07',
                          filingAmount: null,
                          jurisdiction:
                            'LOS ANGELES COUNTY SMALL CLAIMS COURT/INGLEWOOD ,INGLEWOOD ,United States',
                          currentStatus: 'Archived',
                          nameOfDebtors: ['99 CENTS ONLY STORE, CORPORATION'],
                          natureOfClaim: null,
                          nameOfCreditors: ['WALKER, AQUANETTA'],
                          typeOfLegalAction: 'Court Judgement',
                        },
                        {
                          uuid: 'cc687d25-d84f-45ff-a874-0e7f0e4b2889',
                          caseNumber: '02S00038',
                          dateOfFiling: '2002-03-06',
                          filingAmount: null,
                          jurisdiction:
                            'LOS ANGELES COUNTY SMALL CLAIMS COURT/BURBANK ,BURBANK ,United States',
                          currentStatus: 'Archived',
                          nameOfDebtors: [
                            '99 CENTS ONLY STORE REGISTERED OWNER',
                            'SUAREZ, MOISES CORTES DRIVER',
                            '99 CENTS ONLY STORE',
                          ],
                          natureOfClaim: null,
                          nameOfCreditors: [
                            'HOANG, MUI DRIVER',
                            'HOANG, QUYEN',
                          ],
                          typeOfLegalAction: 'Court Judgement',
                        },
                      ],
                      hasOutstandingSuits: [
                        {
                          uuid: 'fa15cced-16a4-42ce-a77d-8de8c98eafb0',
                          caseNumber: '202401396992WJC',
                          dateOfFiling: '2024-05-01',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['IRENE SERNA'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '3e6e0b39-b912-4dd9-9824-6f50f1c09ed2',
                          caseNumber: '202401379520CJC',
                          dateOfFiling: '2024-02-14',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['BENJAMIN BENNANI'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: 'a11f8712-3da7-47af-9fa4-c12de183553a',
                          caseNumber: '202401372965CJC',
                          dateOfFiling: '2024-01-16',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: [
                            'RED HILL VILLAGE LLC',
                            '99 CENTS ONLY STORES LLC',
                          ],
                          nameOfPlaintiff: ['JOANNE SLAUGHTER'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: 'e9a305a8-45ab-42ee-b145-65df08bc91ee',
                          caseNumber: '202301363902CJC',
                          dateOfFiling: '2023-11-20',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['TRACEY NUNGARAY'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '12924914-9f5a-4ad3-9a48-0e81678bf9a7',
                          caseNumber: '202301353281NJC',
                          dateOfFiling: '2023-09-26',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENT ONLY STORES LLC'],
                          nameOfPlaintiff: ['MOHAMMAD MASRI'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '3358d8d2-1285-4c8c-8d4a-baf55adce4ae',
                          caseNumber: '2023CUPP013837',
                          dateOfFiling: '2023-09-11',
                          filingAmount: null,
                          jurisdiction:
                            'VENTURA COUNTY SUPERIOR COURT ,VENTURA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['ALICIA HIDALGO'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '6fc0b4dc-f236-40a7-8e35-69bcb7e1fe60',
                          caseNumber: '2023SC010841',
                          dateOfFiling: '2023-07-05',
                          filingAmount: {
                            value: 10000,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'VENTURA COUNTY SMALL CLAIMS COURT ,VENTURA ,United States',
                          currentStatus: 'Judgment for defendant',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORE'],
                          nameOfPlaintiff: ['LUZ BARRERA DE LA ROCA'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: 'e76ded19-7278-45dc-bf27-e230e687f6d0',
                          caseNumber: '202301321839CJC',
                          dateOfFiling: '2023-04-25',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORE'],
                          nameOfPlaintiff: ['HOAI THANH PHAM'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '5cf84410-befe-41f8-9844-8280ec96714c',
                          caseNumber: '2023SC006960',
                          dateOfFiling: '2023-03-27',
                          filingAmount: {
                            value: 10000,
                            currency: 'USD',
                          },
                          jurisdiction:
                            'VENTURA COUNTY SMALL CLAIMS COURT ,VENTURA ,United States',
                          currentStatus: 'Dismissed',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORE'],
                          nameOfPlaintiff: ['LUZ BARRERA DE LA ROCA'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '5f49cf3f-f897-455e-a9ea-e7b578a25bb2',
                          caseNumber: '2023CUPP006602',
                          dateOfFiling: '2023-03-16',
                          filingAmount: null,
                          jurisdiction:
                            'VENTURA COUNTY SUPERIOR COURT ,VENTURA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['MARIA MAYELA ACOSTA GONZALEZ'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '5d37d676-39b1-44bb-8fa0-3a687117c49d',
                          caseNumber: '202301306409CJC',
                          dateOfFiling: '2023-02-07',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['ABIER AHABBA'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '46a50969-c00e-401f-9f96-777d023c9c0c',
                          caseNumber: '202201289048CJC',
                          dateOfFiling: '2022-10-31',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['JOSE ANTONIO HERNANDEZ LEAL'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '80c95c28-f10e-423e-b703-12a53b1e28fe',
                          caseNumber: '202201281011CJC',
                          dateOfFiling: '2022-09-14',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['MARIA ESCOBAR JIMENEZ'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: 'd4b731e5-6f61-4bfa-8584-834d88ebcf91',
                          caseNumber: '202201277383CJC',
                          dateOfFiling: '2022-08-25',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES LLC'],
                          nameOfPlaintiff: ['DORA QUIROZ'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                        {
                          uuid: '770df545-c59b-4646-ae3e-c1ada3e08171',
                          caseNumber: '202201269824WJC',
                          dateOfFiling: '2022-07-14',
                          filingAmount: null,
                          jurisdiction:
                            'ORANGE COUNTY SUPERIOR COURT ,SANTA ANA ,United States',
                          currentStatus: 'Pending',
                          natureOfClaim: null,
                          nameOfDefendant: ['99 CENTS ONLY STORES'],
                          nameOfPlaintiff: ['ALBERTO CONCHA'],
                          typeOfLegalAction: 'Law Suit Filed (US)',
                        },
                      ],
                      hasOutstandingClaims: [
                        {
                          caseNumber: 'TORSU662319-21',
                          dateOfFiling: '2021-05-14',
                          filingAmount: {
                            value: 2741697,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMITED',
                          ],
                          nameOfPlaintiff: ['PRIESTLEY DEMOLITION INC'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU652190-20',
                          dateOfFiling: '2020-11-27',
                          filingAmount: {
                            value: 211121,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMITED',
                          ],
                          nameOfPlaintiff: [
                            'TRIUMPH ROOFING & SHEET METAL INC',
                          ],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'BRASU3751-20',
                          dateOfFiling: '2020-10-08',
                          filingAmount: {
                            value: 1500000,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELD CONSTRUCTION CO LTD'],
                          nameOfPlaintiff: ['ALISON ANGEL FURTADO'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU66364-20',
                          dateOfFiling: '2020-08-28',
                          filingAmount: {
                            value: 38290,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMITED',
                          ],
                          nameOfPlaintiff: ['G.R.B. STORAGE SYSTEMS INC'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU644338-20',
                          dateOfFiling: '2020-07-21',
                          filingAmount: {
                            value: 644278,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMITED',
                          ],
                          nameOfPlaintiff: ['OAKDALE DRYWALL & ACOUSTICS LTD'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU644248-20',
                          dateOfFiling: '2020-07-20',
                          filingAmount: {
                            value: 5950210,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELD CONSTRUCTION COMPANY'],
                          nameOfPlaintiff: ['OAKDALE DRYWALL & ACOUSTICS LTD'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU644250-20',
                          dateOfFiling: '2020-07-20',
                          filingAmount: {
                            value: 5950220,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELD CONSTRUCTION COMPANY'],
                          nameOfPlaintiff: ['OAKDALE DRYWALL & ACOUSTICS LTD'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU642112-20',
                          dateOfFiling: '2020-06-05',
                          filingAmount: {
                            value: 2000000,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LTD',
                          ],
                          nameOfPlaintiff: ['GREWAL RICHA'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU641584-20',
                          dateOfFiling: '2020-05-27',
                          filingAmount: {
                            value: 417050,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMIED',
                          ],
                          nameOfPlaintiff: ['ASSOCIATED PAINTING LIMITED'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'TORSU639899-20',
                          dateOfFiling: '2020-04-22',
                          filingAmount: {
                            value: 69264,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: [
                            'BONDFIELD CONSTRUCTION COMPANY LIMITED',
                          ],
                          nameOfPlaintiff: ['SKYWAY CANADA LIMITED'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'NMKSU639-20',
                          dateOfFiling: '2020-02-18',
                          filingAmount: {
                            value: 57141,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELDS CONSTRUCTION CO LTD'],
                          nameOfPlaintiff: [
                            'NEXT PLUMBING & HYDRONICS SUPPLY INC',
                          ],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'OSHSU346-20',
                          dateOfFiling: '2020-02-12',
                          filingAmount: {
                            value: 1000000,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELD CONSTRUCTION CO LTD'],
                          nameOfPlaintiff: ['JOAN ACKLOO'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                        {
                          caseNumber: 'OSHSU346-2222',
                          dateOfFiling: '2020-02-12',
                          filingAmount: {
                            value: 1000000,
                            currency: 'CAD',
                          },
                          jurisdiction: null,
                          currentStatus: null,
                          natureOfClaim: 'Claims',
                          nameOfDefendant: ['BONDFIELD CONSTRUCTION CO LTD'],
                          nameOfPlaintiff: ['JOAN ACKLOO'],
                          typeOfLegalAction: 'Law Suit Filed',
                        },
                      ],
                      hasLiensFiledByCompany: [
                        {
                          uuid: 'ff0ddfa50-2094-4690-973b-33e0f6202670',
                          caseNumber: 'U240031431929',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: null,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: null,
                          nameOfLienHolder: [],
                        },
                        {
                          uuid: 'ff0ddfa50-2094-4690-973b-33e0f6202671',
                          caseNumber: 'U2400314319290',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: 316989,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: 'test reason',
                          nameOfLienHolder: [
                            '- CM-529769 - CARLOS HUBBARD ROSA OSEGUEDA',
                          ],
                          courtFilings: [
                            {
                              linkId: '7bit',
                              link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/sampleTest.pdf-1728550012834?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241010%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241010T084652Z&X-Amz-Expires=604800&X-Amz-Signature=719aa9fa36327f35e0b97971b25221be732d4d4386a12114e8a6977c2a7a0279&X-Amz-SignedHeaders=host&x-id=GetObject',
                              urlKey:
                                'applicant/legal/sampleTest.pdf-1728550012834',
                            },
                          ],
                        },
                        {
                          uuid: 'ff0ddfa50-2094-4690-973b-33e0f6202670',
                          caseNumber: 'U2400314319291',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: null,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: null,
                          nameOfLienHolder: [],
                        },
                        {
                          uuid: 'ff0ddfa50-2094-4690-973b-33e0f6202671',
                          caseNumber: 'U2400314319292',
                          lienStatus: 'Open',
                          lienDetails: 'Judgment Lien',
                          dateOfFiling: '2024-04-08',
                          filingAmount: {
                            value: 316989,
                          },
                          jurisdiction:
                            'SECRETARY OF STATE/UCC DIVISION ,SACRAMENTO ,United States',
                          nameOfDebtor: ['99 CENT ONLY STORES, LLC'],
                          reasonForLien: 'test reason',
                          nameOfLienHolder: [
                            '- CM-529769 - CARLOS HUBBARD ROSA OSEGUEDA',
                          ],
                          courtFilings: [
                            {
                              linkId: '7bit',
                              link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/sampleTest.pdf-1728550012834?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241010%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241010T084652Z&X-Amz-Expires=604800&X-Amz-Signature=719aa9fa36327f35e0b97971b25221be732d4d4386a12114e8a6977c2a7a0279&X-Amz-SignedHeaders=host&x-id=GetObject',
                              urlKey:
                                'applicant/legal/sampleTest.pdf-1728550012834',
                            },
                          ],
                        },
                      ],
                      receivershipOrBankruptcyHistory: {
                        hasHistory: true,
                        details: 'test est set estete',
                      },
                      guaranteesForOtherParties: {
                        hasGuarantees: true,
                        details: 'test',
                      },
                      hasGuarantees: {
                        guaranteeDetails: {
                          guarantorInformation: {
                            nameOfGuarantors:
                              'test Name of the Guarantor(s)1 test',
                            position: 'Position(s)',
                            // nameOfGuarantors: null,
                            // position: null,
                          },
                          // beneficiaryInformation: {
                          //   nameOfBeneficiary: 'Name of the Beneficiary',
                          //   relationshipToGuarantor:
                          //     'Relationship to Guarantor',
                          // },
                          beneficiaryInformation: null,
                          guaranteeAgreementDetails: {
                            typeOfGuarantee: 'Type of Guarantee',
                            dateOfAgreement: '2024-10-01T11:52:25.972Z',
                            durationOfGuarantee: 'test Duration of Guarantee',
                          },
                          financialDetails: {
                            amountGuaranteed: null,
                            currency: 'test Currency',
                            termsOfIndebtedness: [
                              {
                                linkId: '7bit',
                                link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/Legalformtable.pdf-1727075376576?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20240923%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240923T070936Z&X-Amz-Expires=604800&X-Amz-Signature=8efc8afcb87f2b0e55d5237f41ec318255e0492130a0f34e065ffad265af1328&X-Amz-SignedHeaders=host&x-id=GetObject',
                                urlKey:
                                  'applicant/legal/Legalformtable.pdf-1727075376576',
                              },
                            ],
                          },
                          collateralAndSecurity: {
                            collateralProvided: 'test Collateral Provided',
                            valuationOfCollateral:
                              'test Valuation of Collateral',
                          },
                          // collateralAndSecurity: null,
                        },
                        impactOnFinancialHealth: {
                          currentStatusOfIndebtedness: {
                            outstandingBalance: 'test Outstanding Balance',
                            paymentStatus: 'Payment Status',
                          },
                        },
                        legalAndRegulatoryCompliance: {
                          guaranteeAgreementDocumentation: {
                            courtFilings: 'test Court Filings',
                            // courtFilings: null,
                          },
                        },
                      },
                      hasChangesInManagement: {
                        changeInControl: {
                          natureOfChangeInControl:
                            'test Nature of Change in Control',
                          dateOfChange: '2024-10-02T10:32:39.838Z',
                          entitiesInvolved: 'test Entities Involved',
                          detailsOfControlTransfer:
                            'test Details of Control Transfer',
                        },
                        changeInOwnership: {
                          changeinShareholder: {
                            natureOfOwnershipChange: 'test1',
                            dateOfChange: '2024-10-09T10:32:48.822Z',
                            newOwners: 'test New Owners',
                            reasonForChange: 'Reason for Change',
                          },
                          minorityshareholder: {
                            natureOfOwnershipChange:
                              'Nature of Ownership Change',
                            dateOfChange: '2024-10-03T10:33:01.022Z',
                            newOwners: 'test New Owners',
                            reasonForChange: 'Reason for Change',
                          },
                          // minorityshareholder: null,
                          majorityshareholder: {
                            natureOfOwnershipChange:
                              'Nature of Ownership Change',
                            reasonForChange: 'Reason for Change',
                            dateOfChange: '2024-10-05T10:33:22.902Z',
                            newOwners: 'New Owners',
                          },
                        },
                        changeInManagement: {
                          natureOfManagementChange:
                            'test Nature of Management Change',
                          dateOfChange: '2024-10-07T10:33:32.461Z',
                          previousManagement: 'test Previous Management',
                          reasonForChange: 'test Reason for Change',
                          newManagement: 'test New Management',
                        },
                        documentation: null,
                        futurePlans: {
                          impendingChanges: 'test Impending Changes',
                        },
                      },
                      relatedCompanies: {
                        hasRelatedCompanies: true,
                        companies: [
                          {
                            name: 'test',
                            natureOfOperations: 'sdsd',
                            ownershipStructure: 'qqqqqqqqqqqqq',
                          },
                          {
                            name: 'test1',
                            natureOfOperations: 'sdsd1',
                            ownershipStructure: 'qqqqqqqqqqqqq1 qqqqqqqqqqqqq1',
                          },
                        ],
                      },
                      hasRelatedCompanies: {
                        globalUltimate: {
                          generalInfoRelatedCompanies: {
                            namesOfRelatedCompanies: 'Number Holdings, Inc.',
                            typeOfRelationship:
                              'test global Type of Relationship',
                            ownershipStructure:
                              'test global Ownership Structure',
                          },
                          companyDetails: {
                            businessActivities: 'Variety store',
                            locations:
                              '4000 Union Pacific Ave, Los Angeles, 90023-3202, United States',
                          },
                          operationalAndManagementDetails: {
                            operationalDependencies:
                              'test global Operational Dependencies',
                          },
                        },
                        domesticUltimate: {
                          generalInfoRelatedCompanies: {
                            namesOfRelatedCompanies: 'Number Holdings, Inc.',
                            typeOfRelationship:
                              'test domestic Type of Relationship',
                            ownershipStructure:
                              'test domestic Ownership Structure',
                          },
                          companyDetails: {
                            businessActivities: 'Variety store',
                            locations:
                              '4000 Union Pacific Ave, Los Angeles, 90023-3202, United States',
                          },
                          operationalAndManagementDetails: {
                            operationalDependencies:
                              'test domestic Operational Dependencies',
                          },
                        },
                        parent: {
                          generalInfoRelatedCompanies: {
                            namesOfRelatedCompanies: 'Number Holdings, Inc.',
                            typeOfRelationship:
                              'test parent  Type of Relationship',
                            ownershipStructure:
                              'test parent Ownership Structure',
                          },
                          companyDetails: {
                            businessActivities: 'Variety store',
                            locations:
                              '4000 Union Pacific Ave, Los Angeles, 90023-3202, United States',
                          },
                          operationalAndManagementDetails: {
                            operationalDependencies:
                              'test parent Operational Dependencies',
                          },
                        },
                      },
                      hasClaimsSuretybond: {
                        generalInformation: {
                          typeofSuretyBond: 'type of surety bond',
                          bondAmount: '12',
                          bondNumber: '123',
                          issuingSuretyCompany: 'Issuing Surety Company',
                        },
                        claimDetails: {
                          natureOftheClaim: 'test',
                          dateOfClaim: '2024-10-11T11:51:30.460Z',
                          claimantInformation: 'Claimant Information',
                        },
                        projectDetails: {
                          projectInvolved: 'Project Involved',
                          scopeOfWork: 'Scope of Work',
                          contractAmount: 'Scope of Work',
                        },
                        financialDetails: {
                          claimAmount: 'Claim Amount',
                          breakdownOfClaim: 'Breakdown of Claim',
                        },
                        resolutionAndSettlement: {
                          currentStatusOfClaim: 'Current Status of Claim',
                          resolutionEfforts: 'Resolution Efforts',
                          outcomeSettlement: 'Outcome or Settlement',
                        },
                        impactOnCompany: {
                          financialImpact: 'Financial Impact',
                        },
                        legalAndDocumentation: {
                          legalRepresentation: 'Legal Representation',
                          courtFilings: 'Court Filings',
                        },
                        riskAssessmentAndMitigation: {
                          mitigationStrategies: 'Mitigation Strategies',
                        },
                        communication: {
                          regulatoryCompliance: 'Regulatory Compliance',
                        },
                        futureRisks: {
                          potentialFutureClaims: 'Potential Future Claims',
                          strategicPlans: 'Strategic Plans',
                        },
                      },
                      hasBuySellAgreement: {
                        genralInfo: {
                          natureOfAgreement: 'Nature of Agreement',
                          dateOfAgreement: '2024-10-15T11:53:37.164Z',
                          partiesInvolved: 'parties',
                        },
                        agreementTerms: {
                          triggeringEvents: 'Triggering Events',
                          valuationMethod: 'Valuation Method',
                          fundingMechanism: 'Funding Mechanism',
                          paymentTerms: 'Payment Terms',
                        },
                        ownershipInfo: {
                          ownershipStructure: 'Ownership Structure',
                          shareholderOrPartnerInterests:
                            'Shareholder/Partner Interests',
                        },
                        financialImpect: {
                          insurancePolicies: 'Insurance Policies',
                        },
                        legalDocumentation: {
                          legalRepresentation: 'Legal Representation',
                        },
                        impectOnBussinessOpretions: {
                          operationalImpact: 'Operational Impact',
                          managementSuccession: 'Management Succession',
                        },
                        complianceAndCommunication: {
                          regulatoryCompliance: 'Regulatory Compliance',
                        },
                        futurePlanning: {
                          reviewAndUpdates: 'Review and Updates',
                        },
                        riskAssessmentAndMitigation: {
                          mitigationStrategies: 'Mitigation Strategies',
                        },
                      },
                      changesInManagement: {
                        hasChanges: true,
                        explanation:
                          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown.',
                      },
                      workOutsideCanada: null,
                      liensFiledAgainstCompany: {
                        hasLiens: true,
                        details: 'test etest  ett t',
                      },
                      stateFederalTax: true,
                      paymentPlan: null,
                      outstandingJudgments: {
                        hasOutstandingJudgments: true,
                        details: 'hfdjf uereur erueriuei ereru',
                      },
                      bondClaims: true,
                      claimsPaid: true,
                      suretyMade: false,
                      whyNotMade: 'fdf etyeyt eyt',
                      disputesWithOthers: {
                        hasDisputes: true,
                        details: 'test',
                      },
                      liensFiledByCompany: {
                        hasLiens: true,
                        details: 'jdkdfdjfdf',
                      },
                      disputesWithSuppliers: {
                        hasDisputes: true,
                        details: 'teteeedsdhgsd',
                        fundingMeans: 'hdjhgsgus',
                      },
                      buySellAgreement: true,
                      assignToApplicant: false,
                      createdAt: '2024-05-20T11:12:31.000Z',
                      updatedAt: '2024-05-20T11:12:31.000Z',
                    },
                    financialInfo: {
                      financialInfoId: 3,
                      brokerId: 2,
                      applicationId: 3,
                      applicationName: 'A5E908',
                      ownersFinancialInfo: [
                        {
                          ownerId: 2,
                          ownerInfo: {
                            position: 'sdsds',
                            nameHoldco: 'Mohini Lakade',
                            dateOfBirth: '1999-12-31T18:30:00.000Z',
                            inPositionSince: 32323,
                            ownershipPercentage: 50,
                          },
                          assetsPrice: [
                            {
                              id: '64633b45-b5b3-485a-9fd9-140aca623cdc',
                              type: 'Cash/GIC',
                              value: 10,
                            },
                            {
                              id: '9fac79ce-3f94-4239-b995-bf517ea534fc',
                              type: 'RRSP',
                              value: 0,
                            },
                            {
                              id: '3d969042-4d34-4796-abad-1ecf5155458a',
                              type: 'Others',
                              value: 0,
                              otherAssetType: 'test other',
                            },
                            {
                              id: '3d969042-4d34-4796-abad-1ecf5155458a',
                              type: 'Others',
                              value: 0,
                              otherAssetType: 'test othersss',
                            },
                            {
                              id: '9fac79ce-3f94-4239-b995-bf517ea534fc',
                              type: 'RRSP',
                              value: 0,
                            },
                          ],
                          totalAssets: 12,
                          assetsRealEstate: [
                            {
                              id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                              value: 0,
                              address: 'rwewew',
                            },
                            {
                              id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                              value: 3433,
                              address: 'qwewrerewewewe',
                            },
                            {
                              id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                              value: 0,
                              address: '',
                            },
                          ],
                          liabilitiesPrice: [
                            {
                              id: 'e73d58f7-1a9f-4fe8-9d0b-ade18856af9b',
                              type: 'Personal loan/Lines of Credit Outstanding',
                              value: null,
                            },
                          ],
                          personalNetWorth: 14,
                          totalLiabilities: 13,
                          assetsResidential: {
                            value: null,
                            address: null,
                          },
                          businessInterests: [
                            {
                              id: '1ee07849-20c2-4819-a9bc-6e33c1b36264',
                              value: 0,
                              legalName: 'test',
                              ownershipPercentage: 0,
                            },
                            {
                              id: '1ee07849-20c2-4819-a9bc-6e33c1b36264',
                              value: 0,
                              legalName: '',
                              ownershipPercentage: 0,
                            },
                          ],
                          liabilitiesResidence: {
                            value: null,
                            mortgage: null,
                          },
                          liabilitiesRealEstate: [
                            {
                              id: '65ec679c-882d-4219-bea1-1b3778ce140c',
                              value: null,
                              mortgage: null,
                            },
                          ],
                        },
                        {
                          ownerId: 6,
                          ownerInfo: {
                            position: null,
                            nameHoldco: '',
                            dateOfBirth: '',
                            inPositionSince: null,
                            ownershipPercentage: null,
                          },
                          assetsPrice: [
                            {
                              id: '6822ee74-3c98-4d81-8102-cd21824f53fb',
                              type: 'Cash/GIC',
                              value: null,
                            },
                          ],
                          totalAssets: null,
                          assetsRealEstate: [
                            {
                              id: '8f0e2576-a5d4-478f-8d11-f598e647165d',
                              value: null,
                              address: null,
                            },
                          ],
                          liabilitiesPrice: [
                            {
                              id: 'e7c9e1ce-a58b-4d63-b22b-0bd535b0113c',
                              type: 'Personal loan/Lines of Credit Outstanding',
                              value: null,
                            },
                          ],
                          personalNetWorth: null,
                          totalLiabilities: null,
                          assetsResidential: {
                            value: null,
                            address: null,
                          },
                          businessInterests: [
                            {
                              id: '4c5e84f5-9840-4af2-92dd-f38a6cbe498f',
                              value: null,
                              legalName: null,
                              ownershipPercentage: null,
                            },
                          ],
                          liabilitiesResidence: {
                            value: null,
                            mortgage: null,
                          },
                          liabilitiesRealEstate: [
                            {
                              id: '34413aea-c2fd-43d8-b074-0fde86e5d338',
                              value: null,
                              mortgage: null,
                            },
                          ],
                        },
                      ],
                      assignToApplicant: false,
                      createdAt: '2024-05-24T12:33:45.000Z',
                      updatedAt: '2024-05-24T12:35:26.000Z',
                    },
                    cms: {
                      cmsId: 3,
                      brokerId: 2,
                      applicationId: 3,
                      applicationName: 'A5E908',
                      name: 'Mohini',
                      position: 'CEO',
                      hiringDate: '2011-05-31T18:30:00.000Z',
                      geoArea: 'USA',
                      workOutsideDetails: {
                        where: null,
                        howOften: null,
                        workOutside: false,
                      },
                      managementControl: [
                        {
                          jobType: 'Personally',
                        },
                        {
                          jobType: 'Weekly',
                        },
                      ],
                      contractors: [
                        {
                          type: 'Excavation',
                          annualSalesPercentage: 30,
                        },
                        {
                          type: 'Concrete',
                          annualSalesPercentage: 70,
                        },
                      ],
                      fixedContract: [
                        {
                          id: 'b96a8956-a2da-4fdb-b8d1-2d338e1af0d3',
                          location: 'USA',
                          annualSales: 30,
                          contractPrice: 10000,
                          contractorType: 'Excavation',
                        },
                        {
                          id: 'd5c0b93c-46c3-4ec1-b21f-cbfc22962aab',
                          location: null,
                          annualSales: 70,
                          contractPrice: null,
                          contractorType: 'Concrete',
                        },
                      ],
                      largestWorkAmount: '50',
                      numberOfContracts: 70,
                      workSubletPercentage: 50,
                      workUndertakenOwnForces: 'Building',
                      workSublet: [
                        {
                          type: 'Excavation',
                        },
                        {
                          type: 'Electrical',
                        },
                        {
                          type: 'Concrete',
                        },
                      ],
                      totalWorkProgramOneTime: null,
                      totalWorkProgram12Months: null,
                      obtainBondsFromSubcontractors: {
                        hasBonds: false,
                        minimumContract: null,
                      },
                      salesPercentageByOwnerType: [
                        {
                          ownerType: 'Government',
                          salesPercentage: 50,
                        },
                        {
                          ownerType: 'Institutions',
                          salesPercentage: 30,
                        },
                        {
                          ownerType: 'Others',
                          otherOwnerType: 'assddd',
                          salesPercentage: 20,
                        },
                      ],
                      workOutsideCanada: {
                        performWorkOutside: true,
                        annualSalesPercentage: [
                          {
                            country: 'India',
                            percentage: 50,
                          },
                          {
                            country: 'Canada',
                            percentage: '25',
                          },
                        ],
                      },
                      employeesUnionized: false,
                      payUnionScaleWages: false,
                      ownerName: 'Mohini',
                      contractPrice: '500000',
                      typeOfProjectOwner: {
                        type: 'Utilities',
                        otherType: null,
                      },
                      typeOfWork: [
                        {
                          type: 'Excavation',
                        },
                      ],
                      assignToApplicant: false,
                      estimatedSubletBudget: '4654',
                      keyPersonnel: [
                        {
                          name: null,
                          position: 'Backend Developer',
                          hiringDate: '2024-06-11T18:30:00.000Z',
                        },
                        {
                          name: 'Femil',
                          position: 'Developer',
                          hiringDate: '2024-06-11T18:30:00.000Z',
                        },
                      ],
                      createdAt: '2024-05-24T12:33:45.000Z',
                      updatedAt: '2024-06-13T12:27:18.000Z',
                    },
                    surety: {
                      suretyId: 18,
                      brokerId: 26,
                      applicationId: 18,
                      applicationName: 'appli233',
                      suretyName: 'sds',
                      totalWorkOnHand: 12,
                      singleJob: 12,
                      reasonChangingSurety: '112',
                      refusedBond: true,
                      refusedBondExplanation: 'sffdff',
                      whoSignSeal: [
                        {
                          lastName: '21',
                          position: '21',
                          firstName: '21',
                        },
                      ],
                      assignToApplicant: false,
                      createdAt: '2024-05-31T08:11:49.000Z',
                      updatedAt: '2024-05-31T12:55:46.000Z',
                    },
                    insurance: {
                      insuranceId: 18,
                      brokerId: 26,
                      applicationId: 18,
                      applicationName: 'appli233',
                      keyManLifeInsurance: {
                        details: [
                          {
                            amount: 546,
                            keyPerson: '12121',
                            beneficiary: '5656',
                          },
                        ],
                        hasKeyManLifeInsurance: true,
                      },
                      commercialGeneralLiabilityInsurance: {
                        limit: 12,
                        provider: '1',
                        hasCommercialGeneralLiabilityInsurance: true,
                      },
                      environmentalInsurance: {
                        limit: 121,
                        provider: '21',
                        hasEnvironmentalInsurance: true,
                      },
                      errorsAndOmissionsInsurance: {
                        limit: 21,
                        provider: '21',
                        hasErrorsAndOmissionsInsurance: true,
                      },
                      fidelityInsurance: {
                        limit: 21,
                        provider: '21',
                        hasFidelityInsurance: true,
                      },
                      equipmentInsurance: {
                        limit: 21,
                        provider: '21',
                        hasEquipmentInsurance: true,
                      },
                      otherInsurance: {
                        details: [
                          {
                            type: '1',
                            limit: 21,
                            provider: '21',
                          },
                        ],
                        hasOtherInsurance: true,
                      },
                      assignToApplicant: false,
                      createdAt: '2024-05-31T08:11:49.000Z',
                      updatedAt: '2024-05-31T12:44:11.000Z',
                    },
                  }}
                />
              </PDFViewer>
            }
          /> */}
          <Route path="/applicant-account" element={<ApplicantAccount />} />
          <Route path="/broker-account" element={<BrokerAccount />} />
          <Route path="/auth/resetPassword" element={<ForgetPassword />} />
          <Route path="/request-email" element={<ForgetEmail />} />
          <Route path="/brokererage-account" element={<BrokererageAccount />} />
          <Route path="/permission/verification" element={<Permission />} />
          <Route
            path="/permission/verification/permission-verify"
            element={
              <PermissionVerify
                permissionInfo={`Data auto populate consent link expired , Your first attempt reached !!`}
              />
            }
          />
          <Route
            path="/permission/verification/permission-granting"
            element={<GrantingPermission />}
          />
          <Route
            path="/permission/verification/resetPassword"
            element={
              <PermissionVerify
                permissionInfo={`Reset Password link has been expired!!`}
              />
            }
          />
          <Route path="/qbo/connect" element={<QboConnect />} />
          <Route path="/qbo/success" element={<QboSuccess />} />

          {/* Owner Public Route */}
          <Route path="/owner/pf" element={<OwnerPersonalFinanceForm />} />
        </>

        {userResponse === 'applicant' ? (
          <>
            <Route
              path="/applicant/profile"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplicantProfile />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/add-your-brokerage"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplicantBroker />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/applicant-accountant"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplicantAccountant />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/applicantqbo"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplicantQBO />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/company-info"
              element={
                <PrivateRoute>
                  <Layout>
                    <QuickbookAlldata />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplicantDashboardTable />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/owner-table"
              element={
                <PrivateRoute>
                  <Layout>
                    <OwnerTabel />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/accountant-table"
              element={
                <PrivateRoute>
                  <Layout>
                    <AccountTabel />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/application/:id"
              element={
                <PrivateRoute>
                  <Stepper />
                </PrivateRoute>
              }
            />
            <Route
              path="/application/awaiting-underwriting"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Application Submitted. Awaiting Underwriting.">
                    <ApplicantAwaitingUnderwriting />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/application/awaiting-input"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Awaiting Input">
                    <ApplicantUnderwriting />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            {/* <Route
                path="/application/awaiting-consent"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting consent to the accounting system">
                      <ApplicantApproval />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              /> */}
            <Route
              path="/applications/declined-by-underwriter"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Declined by Underwriter">
                    <DeclinedBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/approved-by-underwriter"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Approved by Underwriter">
                    <ApprovedBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/drafts"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Drafts">
                    <Drafts />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/working_progress"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Work in Progress">
                    <ReportWorkingProgress />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/working_capital"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Working Capital">
                    <ReportWorkingCapital />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/upload"
              element={
                <PrivateRoute>
                  <Layout>
                    <Upload callesFrom="Upload" />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/account-payable"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Account Payable Aging Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/account-receivable"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Account Receivable Aging Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/profit-loss"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Profit and Loss Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/report/balance-sheet"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Balance Sheet Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/access-request"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerAccessRequest />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant/help"
              element={
                <PrivateRoute>
                  <Layout>
                    <Help />
                  </Layout>
                </PrivateRoute>
              }
            />
          </>
        ) : (
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Layout>
                  <NotFoundPage token={tokenResponse} userType={userResponse} />
                </Layout>
              </PrivateRoute>
            }
          />
        )}

        {userResponse === 'broker' ? (
          <>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerDashboardTable />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/create-application"
              element={
                <PrivateRoute>
                  <Layout>
                    <Stepper />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/application/:id"
              element={
                <PrivateRoute>
                  <Stepper />
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/awaiting-underwriting"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Application Submitted. Awaiting Underwriting.">
                    <ApplicantAwaitingUnderwritingBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/awaiting-input"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Awaiting Input">
                    <ApplicantUnderwritingBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            {/* <Route
                path="/applications/awaiting-consent"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting consent to the accounting system">
                      <ApplicantApprovalBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              /> */}
            <Route
              path="/applications/declined-by-underwriter"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Declined by Underwriter">
                    <DeclinedBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/approved-by-underwriter"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Approved by Underwriter">
                    <ApprovedBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/applications/drafts"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Drafts">
                    <DraftsBroker />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/create-application/:id"
              element={
                <PrivateRoute>
                  <Layout>
                    <Stepper />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/profile"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerProfile />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/working_progress"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Work in Progress">
                    <ReportWorkingProgress />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/working_capital"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Working Capital">
                    <ReportWorkingCapital />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/upload"
              element={
                <PrivateRoute>
                  <Layout>
                    <Upload callesFrom="Upload" />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/applicant-profile"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerApplicantProfile />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/account-payable"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Account Payable Aging Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/account-receivable"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Account Receivable Aging Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/profit-loss"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Profit and Loss Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/report/balance-sheet"
              element={
                <PrivateRoute>
                  <FullScreenLayout title="Balance Sheet Report">
                    <APAgingSummary />
                  </FullScreenLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/access-request"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerAccessRequest />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/broker/help"
              element={
                <PrivateRoute>
                  <Layout>
                    <Help />
                  </Layout>
                </PrivateRoute>
              }
            />
          </>
        ) : (
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Layout>
                  <NotFoundPage token={tokenResponse} userType={userResponse} />
                </Layout>
              </PrivateRoute>
            }
          />
        )}

        {userResponse === 'brokerage' ? (
          <>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerageProfile />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/brokerage-managment"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokererageManagment />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/brokerage-enterCl"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokererageEnterCl />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/plans"
              element={
                <PrivateRoute>
                  <Layout>
                    <ThreeTierPricing />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/billing"
              element={
                <PrivateRoute>
                  <Layout>
                    <BiilingPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/checkout"
              element={
                <PrivateRoute>
                  <Layout>
                    <Checkout />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/brokerage-enterEAndO"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokererageEnterEAndO />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brokerage/brokerageCLfrom"
              element={
                <PrivateRoute>
                  <Layout>
                    <BrokerageCLform />
                  </Layout>
                </PrivateRoute>
              }
            />
          </>
        ) : (
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Layout>
                  <NotFoundPage token={tokenResponse} userType={userResponse} />
                </Layout>
              </PrivateRoute>
            }
          />
        )}

        <Route
          path="/accountant/accountantSignupLink"
          element={
            <PrivateRoute>
              <Layout>
                <AccountantSignupLink />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/accountant/accountantDashboard"
          element={
            <PrivateRoute>
              <Layout>
                <AccountantDashboard />
              </Layout>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default Routing;
