import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import moment from 'moment';
import { FaHistory, FaUserEdit } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { RiChatHistoryLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApplicationTableAction from 'src/Component/Common/TableActions/Header';
import { useAppToast } from 'src/hooks';
import { useApplicationNavigation } from 'src/hooks/useApplicationNavigation';
import PdfContent from 'src/Pages/Reports/components/PdfModal';
import { getBrokerDashboardAppList } from 'src/Redux/Applications/slice';
import {
  ApplicantBrokerage,
  Applicantpermission,
  BrokerageDashboardState,
  checkUserProfileStatus,
  InitialApplication,
  removeBrokerDashboardType,
} from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { RootState, useAppSelector } from 'src/Redux/Store';
import { socket } from 'src/Socket';
import { AutoPopulateModal } from './BrokerDashboard';
import GlobalHistoryModal from './BrokerDashboardList/components/GlobalHistoryModal';

const BrokerDashboardTable = () => {
  const { handelEdit, handelHistory, handelView } = useApplicationNavigation();
  const appliactionsSlice = useAppSelector(
    (state: RootState) => state.appliactionsSlice
  );
  const data = appliactionsSlice && appliactionsSlice?.brokerDashboardAppList;

  const toast = useAppToast();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userType = localStorage.getItem('userType');

  //new add
  const navigate = useNavigate();
  const [isloadingApp, setIsloadingApp] = useState<boolean>(false);
  const [selectedBrokerageId, setSelectedBrokerageId] = useState('');
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState(false);
  const [qboModal, setQboModal] = useState(false);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [isOngoingDataLoading, setIsOngoingDataLoading] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [loader, setLoader] = useState<{
    confirmBtnLoader: boolean;
    declinedBtnloader: boolean;
  }>({ confirmBtnLoader: false, declinedBtnloader: false });
  const [applicationCount, setApplicationCount] = useState({});
  const [isUpdateApplication, setIsUpdateApplication] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState<Record<
    string,
    any
  > | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  const brokerageDashboard: BrokerageDashboardState = useAppSelector(
    (state) => state.brokerageDashboard
  );

  useEffect(() => {
    const { status, error, type } = appliactionsSlice;
    switch (status) {
      case 'loading':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          setIsLoading(false);
        }
        break;
      case 'failed':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          toast({
            title: error,
            status: 'error',
          });
          setIsLoading(false);
        }
        break;
    }
  }, [appliactionsSlice?.status]);

  //new add
  useEffect(() => {
    dispatch(ApplicantBrokerage() as any);
    dispatch(getBrokerDashboardAppList() as any);
  }, []);

  //(comment below code due to  this changes -> on select applicant -> everytime create new application) (below code required if not create fresh application everytime)
  // useEffect(() => {
  //   const selectApplicationData = applicantOptions?.find(
  //     (item: any) => item?.applicantId === Number(selectedBrokerageId)
  //   );
  //   if (selectApplicationData) {
  //     setApplicationId(selectApplicationData?.ongoingApplicationId);
  //   }
  // }, [selectedBrokerageId]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData, user, error } =
      brokerageDashboard;
    switch (status) {
      case 'loading':
        if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
          setLoader({ ...loader, confirmBtnLoader: true });
        }
        if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
          setLoader({ ...loader, declinedBtnloader: true });
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   setIsLoading(true);
        // }
        break;
      case 'succeed':
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
        }
        //(comment below code due to  this changes -> on select applicant -> everytime create new application) (below code required if not create fresh application everytime)
        // if (type === 'GET_APPLICANT_PROFILE_STATUS' && selectedBrokerageId) {
        // if (brokerageDashboard.selectedApplicantData?.isProfileCompleted) {
        //   fetchData(selectedBrokerageId);
        // }
        // }
        if (
          type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
          type === 'ACCOUNTING_DATA_PERMISSION_DECLINED'
        ) {
          toast({
            title: user?.data?.msg,
            status: 'success',
          });
          if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
            setLoader({ ...loader, confirmBtnLoader: false });
          } else if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
            setLoader({ ...loader, declinedBtnloader: false });
          }
          navigate(`/application/${user?.data?.applicationId}`);
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   setIsLoading(false);
        // }
        break;
      case 'failed':
        if (
          type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
          type === 'ACCOUNTING_DATA_PERMISSION_DECLINED'
        ) {
          toast({
            title: error,
            status: 'error',
          });
          setLoader({
            ...loader,
            confirmBtnLoader: false,
            declinedBtnloader: false,
          });
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   toast({
        //     title: error,
        //     status: 'error',
        //   });
        //   setIsLoading(false);
        // }
        break;

      default:
        break;
    }

    return () => {
      dispatch(removeBrokerDashboardType({}) as any);
    };
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    // 1
    socket.on('permissionGranted', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 2
    socket.on('permissionDenied', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 3
    socket.on('updateFormByApplicant', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 4
    // socket.on("updateFormByAccountant", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // 5
    // socket.on("updateFormByOwner", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // Cleanup function
    return () => {
      socket.off('permissionGranted');
      socket.off('permissionDenied');
      socket.off('updateFormByApplicant');
      // socket.off("updateFormByAccountant");
      // socket.off("updateFormByOwner");
    };
  }, []);

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    setSelectedBrokerageId((prevSelectedId) => selectedId);

    if (selectedId) {
      setIsloadingApp(true);
      dispatch(checkUserProfileStatus(selectedId) as any);

      setIsloadingApp(false);
    }
  };

  // const fetchData = async (id: number | string) => {
  //   try {
  //     setIsOngoingDataLoading(true);
  //     const Token = localStorage.getItem('Token');
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${Token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     };

  //     const response = await fetch(
  //       `${BASEURL}/application/ongoing/${id}`,
  //       config
  //     );

  //     if (!response.ok) {
  //       setIsOngoingDataLoading(false);
  //       throw new Error('Network response was not ok');
  //     }

  //     const result = await response.json();
  //     // const OngoingData = result?.data?.applicationId ? true : false;
  //     // setData(OngoingData);
  //     setApplicationId(result?.data?.applicationId);
  //     setIsOngoingDataLoading(false);
  //   } catch (error) {
  //     setIsOngoingDataLoading(false);
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const confirmAutoPopulate = () => {
    if (applicationId) {
      navigate(`/application/${applicationId}`);
    } else {
      setIsUpdateApplication(true);
      try {
        dispatch(
          Applicantpermission({
            applicantId: parseFloat(selectedBrokerageId),
          }) as any
        );
      } catch (error: any) {
        toast({
          title: error.message,
          status: 'error',
        });
      }
      setIsUpdateApplication(false);
    }
  };
  const declinedAutoPopulate = () => {
    setIsUpdateApplication(true);
    try {
      dispatch(
        InitialApplication({
          id: parseFloat(selectedBrokerageId),
        }) as any
      );
    } catch (error: any) {
      toast({
        title: error.message,
        status: 'error',
      });
    }
    setIsUpdateApplication(false);
  };

  const getBrokerDetails = (broker: any) => {
    let brokerData = {
      firstName: broker?.firstName,
      lastName: broker?.lastName,
      contactNumber: broker?.contactNumber,
      brokerageName: broker?.brokerage?.legalName,
      email: broker?.email,
    };
    return brokerData;
  };

  const getFormData = (user: any) => {
    let formData = {
      accountingSystem: user?.accountingSystem,
      creditBureau: user?.creditBureau,
      financialInfo: user?.financialInfo,
      cms: user?.cms,
      surety: user?.surety,
      insurance: user?.insurance,
    };
    return formData;
  };

  console.log('data', data, brokerageDashboard.selectedApplicantData);

  return (
    <Box>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        borderBottomWidth="1px"
        mb={2}
        flexWrap={'wrap'}
        py={1}
      >
        <Heading
          p="4"
          fontSize={'24px'}
          fontWeight={'600'}
          //   borderBottomWidth="1px"
        >
          Dashboard Details
        </Heading>
        <Flex
          alignItems={'center'}
          gap={{ base: 3, md: 5 }}
          mx={4}
          flexWrap={'wrap'}
        >
          <Text>Applications remaining : {400 - (data?.length || 0)}</Text>
          <Flex
            alignItems={'center'}
            gap={{ base: 3, md: 5 }}
            flexWrap={'wrap'}
          >
            <Box minW="max-content">
              <Select
                w="100%"
                onChange={handleBrokerageSelect}
                value={selectedBrokerageId}
                name="userData.brokerageId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Applicant"
                />
                {applicantOptions.map((user?: any, index?: number) => (
                  <option
                    style={{ color: 'black' }}
                    key={index}
                    value={user?.applicantId ?? ''}
                    label={user?.companyName ?? ''}
                  />
                ))}
              </Select>
            </Box>
            <Box minW={'200px'}>
              {/* <Button
              isLoading={
                (brokerageDashboard.status === 'loading' &&
                  brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS') ||
                isOngoingDataLoading
              }
              w={'100%'}
              type="submit"
              bg="#114684"
              color="white"
              _hover={{ bg: '#114684' }}
              onClick={() =>
                applicationId
                  ? navigate(`/application/${applicationId}`)
                  : setQboModal(true)
              }
              isDisabled={isloadingApp || !selectedBrokerageId}
              position={'relative'}
            >
              {!brokerageDashboard.selectedApplicantData?.isProfileCompleted ? (
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `/broker/applicant-profile?applicantId=${selectedBrokerageId}`
                    );
                  }}
                >
                  Complete Applicant Profile
                </Box>
              ) : applicationId ? (
                'Update Application'
              ) : (
                'Create Application'
              )}
            </Button> */}
              {/* {button text change based on application status for every time create new application} */}
              <Button
                isLoading={
                  brokerageDashboard.status === 'loading' &&
                  brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS'
                }
                w={'100%'}
                type="submit"
                bg="#114684"
                color="white"
                _hover={{ bg: '#114684' }}
                onClick={() =>
                  !brokerageDashboard.selectedApplicantData?.isProfileCompleted
                    ? navigate(
                        `/broker/applicant-profile?applicantId=${selectedBrokerageId}`
                      )
                    : !!brokerageDashboard.selectedApplicantData
                          ?.qboAccountLinked
                      ? declinedAutoPopulate()
                      : setQboModal(true)
                }
                isDisabled={isloadingApp || !selectedBrokerageId}
                position={'relative'}
              >
                {selectedBrokerageId &&
                !brokerageDashboard.selectedApplicantData?.isProfileCompleted
                  ? 'Complete Applicant Profile'
                  : 'Create Application'}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {isLoading ? (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box w={'100%'} overflowX={'auto'}>
          <Box
            pl={[2, 2, 4, 4, 4]}
            pr={[2, 2, 4, 4, 4]}
            mr={'6px'}
            minWidth="922px"
          >
            <Grid
              bg="#114684"
              display={'grid'}
              // justifyContent={'space-between'}
              templateColumns="repeat(12, 1fr)"
              h={'auto'}
              borderRadius={3}
              alignItems={'center'}
              p={2}
              gap={[1, 1, 2, 2, 2]}
              w={'100%'}
              color={'white'}
              fontWeight={500}
              fontSize={[10, 13, 13, 13, 13]}
            >
              <GridItem colSpan={2}>
                <Text textAlign={'start'}>Application Code</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <Text textAlign={'start'}>Created Date</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <Text textAlign={'start'}>Applicant Name</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Text textAlign={'start'}>Status</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Text textAlign={'start'}>Awaiting Input</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <Text textAlign={'start'}>Broker Name</Text>
              </GridItem>
              <GridItem
                colSpan={2}
                display={'flex'}
                justifyContent={'end'}
                gap={[2, 2, 3, 3, 3]}
                color={'white'}
                fontWeight={500}
                fontSize={[10, 13, 13, 13, 13]}
              >
                <ApplicationTableAction
                  icons={{
                    document: true,
                  }}
                />
              </GridItem>
            </Grid>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            // mt={2}
            py={2}
            overflow={'auto'}
            // maxHeight={`calc(100vh - ${(data?.length ?? 0) * 2 + 167}px)`}
            maxHeight={{
              base: `calc(100vh - ${(data?.length ?? 0) * 2 + 294}px)`,
              md: `calc(100vh - ${(data?.length ?? 0) * 2 + 253}px)`,
              lg: `calc(100vh - ${(data?.length ?? 0) * 2 + 167}px)`,
            }}
            minWidth="922px"
          >
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((user: any) => (
                <>
                  <Box key={user?.id} pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
                    <Box>
                      <Grid
                        bg="#ffa011"
                        gap={2}
                        h={'auto'}
                        borderRadius={3}
                        p={2}
                        color={'black'}
                        fontWeight={500}
                        fontSize={13}
                        templateColumns={'repeat(12, 1fr)'}
                      >
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user.applicationName}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {moment(user.createdAt).format('MM/DD/YYYY')}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.applicant?.companyName}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Text textAlign={'start'}>
                            {user.brokerDashboard}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Text textAlign={'start'}>
                            {user?.isAwaitingInput ? 'Yes' : 'No'}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.broker?.firstName} {user?.broker?.lastName}
                          </Text>
                        </GridItem>

                        <GridItem
                          colSpan={2}
                          display={'flex'}
                          justifyContent={'end'}
                          gap={[3, 4]}
                          w={'100%'}
                          color={'black'}
                          fontSize={13}
                        >
                          {[
                            'Application Submitted. Awaiting Underwriting',
                            'Approved by Underwriter',
                            'Declined by Underwriter',
                            'Completed',
                          ].includes(user.brokerDashboard) && (
                            <Box
                              cursor={'pointer'}
                              _hover={{ color: '#114684' }}
                              minW={'25px'}
                            >
                              <PdfContent
                                applicationId={user?.applicationId}
                                companyName={user?.accountingSystem?.legalName}
                                brokerInfo={getBrokerDetails(user?.broker)}
                                allFormData={getFormData(user)}
                                qboCompanyName={
                                  user?.autoPopulateStatus?.qboCompanyName
                                }
                                user={user}
                              />
                            </Box>
                          )}
                          <Box
                            minW={'25px'}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() =>
                              handelView({
                                applicationId: user.applicationId,
                                status: user.brokerDashboard,
                              })
                            }
                          >
                            <IoEyeSharp size={20} />
                          </Box>
                          {/* <Box
                            minW={'25px'}
                            cursor={
                              [
                                'Approved by Underwriter',
                                'Declined by Underwriter',
                                'Completed',
                              ].includes(user.brokerDashboard)
                                ? 'default'
                                : 'pointer'
                            }
                            _hover={{ color: '#114684' }}
                            onClick={
                              [
                                'Approved by Underwriter',
                                'Declined by Underwriter',
                                'Completed',
                              ].includes(user.brokerDashboard)
                                ? () => {}
                                : () => {
                                    handelEdit({
                                      applicationId: user.applicationId,
                                      status: user.brokerDashboard,
                                    });
                                  }
                            }
                          >
                            <FaUserEdit
                              size={20}
                              visibility={
                                [
                                  'Approved by Underwriter',
                                  'Declined by Underwriter',
                                  'Completed',
                                ].includes(user.brokerDashboard)
                                  ? 'hidden'
                                  : 'visible'
                              }
                            />
                          </Box> */}
                          <Box
                            minW={'25px'}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() => {
                              handelEdit({
                                applicationId: user.applicationId,
                                status: user.brokerDashboard,
                              });
                            }}
                          >
                            <FaUserEdit size={20} />
                          </Box>
                          <Flex gap={2} flexWrap={'nowrap'}>
                            <Box
                              cursor={'pointer'}
                              _hover={{ color: '#114684' }}
                              onClick={() => {
                                setApplicationDetails({
                                  applicationCode: user?.applicationName,
                                  applicantName: user?.applicant?.companyName,
                                  brokerName: `${user?.broker?.firstName} ${user?.broker?.lastName}`,
                                  applicationId: user.applicationId,
                                });
                                open();
                              }}
                            >
                              <Tooltip
                                fontSize="md"
                                label={'Global History'}
                                placement={'auto'}
                                hasArrow
                              >
                                <Box>
                                  <RiChatHistoryLine size={20} />
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box
                              cursor={'pointer'}
                              _hover={{ color: '#114684' }}
                              onClick={() =>
                                handelHistory({
                                  applicationId: user.applicationId,
                                  applicantId: user.applicantId,
                                  status: user.brokerDashboard,
                                })
                              }
                            >
                              <Tooltip
                                fontSize="md"
                                label={'History'}
                                placement={'auto'}
                                hasArrow
                              >
                                <Box>
                                  <FaHistory size={18} />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <>
                <Box pl={4} pr={4}>
                  <Box
                    bg="#ffa011"
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    h={10}
                    borderRadius={3}
                    alignItems={'center'}
                    p={2}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      color={'black'}
                      fontWeight={500}
                      fontSize={15}
                    >
                      <Box width={'100%'}>
                        <Text textAlign={'center'}>Not found</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      <AutoPopulateModal
        isOpen={qboModal}
        onClose={() => setQboModal(false)}
        onConfirm={() => {
          setQboModal(false);
          setAutoPopulateModalOpen(true);
        }}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Yes"
        declinedButtonText="No"
        title={`Do you use QuickBooks (QBO) online?`}
        loader={loader}
      />
      <AutoPopulateModal
        isOpen={autoPopulateModalOpen}
        onClose={() => setAutoPopulateModalOpen(false)}
        onConfirm={confirmAutoPopulate}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Approve"
        declinedButtonText="Decline"
        title={`Can we have your permission to access your accounting data to
        generate financial reports for insurance purposes?`}
        subTitle={`Why do we need this`}
        tooltip={`Securely pulling financial information from your accounting system significantly expedites the bonding and insurance underwriting process`}
        loader={loader}
      />

      <GlobalHistoryModal
        isOpen={opened}
        onClose={close}
        applicationDetails={applicationDetails}
      />
    </Box>
  );
};

export default BrokerDashboardTable;
