import {
  Box,
  Button as ChakraButton,
  Flex,
  Heading,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Button, FileInput, List, Loader, Textarea } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CgComment, CgTrash } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from 'src/Redux/Store';
// import { contentType } from 'mime-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { getApplicationsByApplicant } from 'src/Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { listAllReports, uploadReports } from 'src/Redux/UploadReports/slice';
import {
  MAX_UPLOAD_SIZE,
  PDF_CSV_EXECLE_TYPES,
} from 'src/constants/UploadDocuments';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { isFileTypeAllowed } from 'src/utils/helpers';
import { UploadSchema } from '../../ValidationSchema/Pages/Index';
import UploadFilesTable from '../Reports/components/uploadFiles/UploadFilesTable';
import {
  financialOption,
  financialStatementOption,
  OtherOptionWithtaxingEntity,
} from './data';
import { TZipName } from './types';
import { getSecondDropDownOption } from './utils';

export interface Props {
  callesFrom: string;
}

function UploadDropdowns({ callesFrom }: Props) {
  // states
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [zipName, setZipName] = useState<TZipName | null>(null);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );

  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { showBox } = location.state || {};
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const userType = localStorage.getItem('userType');
  const createApplicationForm = useSelector(
    (state: RootState) => state.createApplicationFormSlice
  );
  const keydata =
    createApplicationForm?.applications.length > 0
      ? createApplicationForm?.applications[0]?.cms?.keyPersonnel
      : [];
  const paymentPlan =
    createApplicationForm?.applications[0]?.creditBureau?.paymentPlan;
  const uploadReportsStore = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  // const userData = useSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const brokerageDashboard = useSelector(
    (state: RootState) => state.brokerageDashboard
  );
  const keypersonneldata =
    appliactionsSlice?.currentApplication?.cms?.keyPersonnel;
  // let AccoutingFormData = userData && userData?.data?.accountingSystem;
  let CreditBureauFormData =
    appliactionsSlice && appliactionsSlice?.currentApplication?.creditBureau;

  // const paramsData = useParams<{ id: string }>();

  // const { showHistory, applicantId } = location.state || {};
  // const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
  let Applicantbrokerage =
    appliactionsSlice && appliactionsSlice?.currentApplication;
  let currentApplicationId = Applicantbrokerage?.applicationId;
  let currentApplicantId = Applicantbrokerage?.applicantId;
  let ApplicationData =
    createApplicationForm && createApplicationForm?.applications;

  interface FormValues {
    applicationId: string;
    applicantId: string;
    financial: string;
    financialYear: string;
    financialStatement: string;
    financialFileWithComment: { file: File; comment?: string | null }[] | null;
  }
  const initialValues: FormValues = {
    applicationId: '',
    applicantId: '',
    financial: '',
    financialYear: '',
    financialStatement: '',
    financialFileWithComment: null,
  };

  const toast = useAppToast();

  useEffect(() => {
    const { status, type, error } = createApplicationForm;

    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
          toast({
            status: 'error',
            title: error,
          });
          resetForm();
          // setFieldValue('applicantId', '');
        }
        break;
      }

      default:
        break;
    }
  }, [createApplicationForm.status]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    if (S3UploadResponse) {
      dispatch(
        uploadReports({
          applicationId: Number(values.applicationId),
          reportInfo: {
            reportName:
              values.financial === 'Financial Statement'
                ? values.financialStatement
                : values.financialYear,
            timeFrame:
              values.financial === 'Financial Statement'
                ? values.financialYear
                : null,
            type: values.financial,
          },
          reportLinks:
            (S3UploadResponse as any)?.data?.map(
              (value: any, index: number) => {
                return {
                  link: value?.location,
                  urlKey: value?.key,
                  comment:
                    values?.financialFileWithComment?.[index].comment ?? null,
                };
              }
            ) ?? [],
        })
      );
    }
    return () => {};
  }, [S3UploadResponse]);

  const submitReportsHandler = async (values: FormValues) => {
    try {
      const localFiles = values.financialFileWithComment?.map(
        (data) => data.file
      );
      if (localFiles?.length) {
        const formData = new FormData();
        localFiles.forEach((file, index) => {
          formData.append(`files`, file);
        });

        await uploadFilesInS3(formData);

        if (S3UploadError) {
          toast({
            title: 'Erro in file uploading!!',
            status: 'error',
          });
          return;
        }
      }
    } catch (error) {
      console.log('submitReportsHandler', error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: UploadSchema,
    onSubmit: submitReportsHandler,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    resetForm,
    setTouched,
  } = formik;

  const resetData = () => {
    setFieldValue('financial', initialValues.financial);
    setFieldValue('financialYear', initialValues.financialYear);
    setFieldValue('financialStatement', initialValues.financialStatement);
    setFieldValue(
      'financialFileWithComment',
      initialValues.financialFileWithComment
    );
    setTouched({ ...touched, financialFileWithComment: false });
  };

  useEffect(() => {
    const { status, type } = uploadReportsStore;
    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(false);
        }
        if (type === 'POST_UPLOAD_REPORTS') {
          toast({
            title: 'Report uploaded successfully!!',
            status: 'success',
          });
          resetData();
          values?.applicationId &&
            dispatch(
              listAllReports({
                applicationId: Number(values.applicationId),
              })
            );
        }
        if (type === 'DELETE_REPORT') {
          toast({
            status: 'success',
            title: 'Report deleted successfully',
          });
          dispatch(
            listAllReports({
              applicationId: Number(values.applicationId),
            })
          );
        }
        if (type === 'UPDATE_REPORT') {
          toast({
            status: 'success',
            title: 'Report updated successfully',
          });
          dispatch(
            listAllReports({
              applicationId: Number(values.applicationId),
            })
          );
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(false);
        }
        break;
      }
    }
  }, [uploadReportsStore.status]);

  useEffect(() => {
    if (userType === 'applicant' && callesFrom !== 'Documents') {
      const applicantId = localStorage.getItem('userID');
      formik.setFieldValue('applicantId', applicantId);
    }
  }, [userType]);

  useEffect(() => {
    if (
      callesFrom === 'Documents' &&
      currentApplicationId &&
      Applicantbrokerage
    ) {
      formik.setFieldValue('applicationId', currentApplicationId);
      setZipName({
        applicantName: Applicantbrokerage?.applicant?.companyName,
        applicationName: Applicantbrokerage?.applicationName,
      });
      // fetchOngoingApplication();
    }
  }, [currentApplicationId]);

  useEffect(() => {
    if (callesFrom === 'Documents' && currentApplicantId) {
      // fetchOngoingApplicant();
      formik.setFieldValue('applicantId', currentApplicantId);
    }
  }, [currentApplicantId, dispatch, callesFrom]);

  useEffect(() => {
    if (callesFrom !== 'Documents') {
      dispatch(ApplicantBrokerage());
    }
    // if (callesFrom !== 'Documents') {
    //   setFieldValue('applicationId', currentApplicationId);
    // }
  }, []);
  useEffect(() => {
    if (values.applicantId !== '') {
      if (callesFrom !== 'Documents')
        dispatch(
          getApplicationsByApplicant({
            applicantId: Number(values.applicantId),
          })
        );
    }
  }, [values.applicantId]);
  useEffect(() => {
    if (values.applicationId)
      dispatch(
        listAllReports({
          applicationId: Number(values.applicationId),
        })
      );
  }, [values.applicationId]);

  // const fetchOngoingApplicant = async () => {
  //   let id = !showHistory && ongoingID ? ongoingID : 0;
  //   id = showHistory && applicantId ? applicantId : id;
  //   formik.setFieldValue('applicantId', id);
  //   let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
  //   if (actionResult?.payload?.status === 401) {
  //     navigate('/login');
  //   }
  // };

  const fetchOngoingApplication = async () => {
    dispatch(
      getApplicationById({
        applicationId: currentApplicationId ?? Number(values?.applicationId),
      }) as any
    );
  };

  const toggleCommentBox = (index: number, val: string | null) => {
    if (val !== null)
      setFieldValue(`financialFileWithComment[${index}].comment`, null);
    else setFieldValue(`financialFileWithComment[${index}].comment`, '');
  };

  const handleFileInputUpload = (payload: File | File[]) => {
    const files = Array.isArray(payload) ? payload : [payload];

    const isValid = files.map((file) => ({
      typeError: !isFileTypeAllowed(file.name, PDF_CSV_EXECLE_TYPES),
      sizeError: file.size > MAX_UPLOAD_SIZE,
    }));

    if (isValid.some((error) => error.typeError)) {
      toast({
        title: 'Invalid file type!',
        status: 'error',
      });
      return;
    }

    if (isValid.some((error) => error.sizeError)) {
      toast({
        title: `Max file size allowed is ${MAX_UPLOAD_SIZE / 1024 ** 2} MB!`,
        status: 'error',
      });
      return;
    }

    const newFiles = files.map((file) => ({
      file,
      comment: null,
    }));

    setFieldValue('financialFileWithComment', newFiles);
  };

  return (
    <>
      <Box py={5}>
        {!showBox && (
          <>
            <Box
              display={{ base: 'block', md: 'flex' }}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              borderBottom={'1px solid #E2E8F0'}
            >
              <Heading
                m="4"
                fontSize={'24px'}
                fontWeight={'600'}
                // borderBottomWidth="1px"
              >
                Upload Documents
              </Heading>
              {/* <PdfContent /> */}
            </Box>
            <Box p={5}>
              <form onSubmit={formik.handleSubmit}>
                <Flex flexDirection={'column'} columnGap={5}>
                  <Flex mb={5} gap={1} flexWrap={'wrap'}>
                    {userType === 'broker' && callesFrom !== 'Documents' && (
                      <Box>
                        <Box maxW="max-content">
                          <Select
                            w="100%"
                            onChange={(event) => {
                              const selectedOption =
                                event.target.options[
                                  event.target.selectedIndex
                                ];
                              const label =
                                selectedOption.getAttribute('label') ?? '';
                              setZipName({
                                applicationName: zipName?.applicationName ?? '',
                                applicantName: label,
                              });

                              setFieldValue('applicantId', event.target.value);
                            }}
                            value={formik?.values?.applicantId ?? ''}
                            name="applicantId"
                            borderRadius="5px"
                            bg={'#114684'}
                            color={'white'}
                            textAlign={'center'}
                          >
                            <option
                              value={''}
                              style={{ color: 'black' }}
                              label="Select Applicant"
                            />
                            {applicantOptions.map((applicant) => (
                              <option
                                style={{ color: 'black' }}
                                key={applicant?.applicantId}
                                value={applicant?.applicantId ?? ''}
                                label={applicant?.companyName ?? ''}
                                aria-label={applicant?.companyName ?? ''}
                              />
                            ))}
                          </Select>
                        </Box>
                        {touched.applicantId && errors.applicantId && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {errors.applicantId}
                          </div>
                        )}
                      </Box>
                    )}
                    {values?.applicantId &&
                      (createApplicationForm.status === 'succeed' ||
                        callesFrom !== 'Documents') &&
                      callesFrom !== 'Documents' && (
                        <Box>
                          <Box maxW="max-content">
                            <Select
                              w="100%"
                              onChange={(event) => {
                                const selectedOption =
                                  event.target.options[
                                    event.target.selectedIndex
                                  ];
                                const label =
                                  selectedOption.getAttribute('label') ?? '';
                                setZipName({
                                  applicantName: zipName?.applicantName ?? '',
                                  applicationName: label,
                                });

                                setFieldValue(
                                  'applicationId',
                                  event.target.value
                                );
                              }}
                              value={formik?.values?.applicationId ?? ''}
                              name="applicationId"
                              borderRadius="5px"
                              bg={'#114684'}
                              color={'white'}
                              textAlign={'center'}
                            >
                              <option
                                value={''}
                                style={{ color: 'black' }}
                                label="Select Application"
                              />
                              {Array.isArray(ApplicationData) &&
                                ApplicationData?.map(
                                  (user?: any, index?: number) => (
                                    <option
                                      style={{ color: 'black' }}
                                      key={index}
                                      value={user?.applicationId ?? ''}
                                      label={user?.applicationName ?? ''}
                                    />
                                  )
                                )}
                            </Select>
                          </Box>
                          {touched.applicationId && errors.applicationId && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.applicationId}
                            </div>
                          )}
                        </Box>
                      )}
                    {values?.applicantId && values?.applicationId && (
                      // createApplicationForm.status === 'succeed' &&
                      <Box>
                        <Box maxW="max-content">
                          <Select
                            name="financial"
                            id="financial"
                            w="100%"
                            borderRadius="5px"
                            bg={'#114684'}
                            color={'white'}
                            textAlign={'center'}
                            value={formik?.values?.financial ?? ''}
                            onChange={(e) => {
                              formik.setFieldValue('financial', e.target.value);
                              // formik.setFieldValue('financialYear', null);
                              // formik.setFieldValue('financialStatement', null);
                              if (
                                values?.financialFileWithComment &&
                                values?.financialFileWithComment?.length > 1 &&
                                e.target.value === 'Financial Statement'
                              ) {
                                formik.setFieldValue(
                                  'financialFileWithComment',
                                  values.financialFileWithComment?.splice(0, 1)
                                );
                              }
                            }}
                          >
                            <option
                              value={''}
                              style={{ color: 'black' }}
                              label="Select Option"
                            />
                            {Array.isArray(financialOption) &&
                              financialOption.map(
                                (item: any, index: number) => (
                                  <option
                                    style={{ color: 'black' }}
                                    key={index}
                                    value={item.value}
                                    label={item.label}
                                  />
                                )
                              )}
                          </Select>
                        </Box>
                        {touched.financial &&
                          errors.financial &&
                          values.financial === '' && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.financial}
                            </div>
                          )}
                      </Box>
                    )}
                    {createApplicationForm.status === 'loading' && (
                      <Flex alignItems={'center'}>
                        <Loader size={'sm'} h={'100%'} />
                      </Flex>
                    )}

                    {values?.applicantId &&
                      (createApplicationForm.status === 'succeed' ||
                        callesFrom === 'Documents') &&
                      values?.applicationId &&
                      formik?.values?.financial &&
                      formik?.values?.financial === 'Financial Statement' && (
                        <Box>
                          <Box maxW="max-content">
                            <Select
                              w="100%"
                              borderRadius="5px"
                              bg={'#114684'}
                              color={'white'}
                              textAlign={'center'}
                              onChange={handleChange}
                              value={formik?.values?.financialStatement ?? ''}
                              name="financialStatement"
                            >
                              <option
                                value={''}
                                style={{ color: 'black' }}
                                label="Select Option"
                              />
                              {Array.isArray(financialStatementOption) &&
                                financialStatementOption.map(
                                  (item: any, index: number) => (
                                    <option
                                      style={{ color: 'black' }}
                                      key={index}
                                      value={item.value}
                                      label={item.label}
                                    />
                                  )
                                )}
                            </Select>
                          </Box>
                          {touched.financialStatement &&
                            errors.financialStatement && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.financialStatement}
                              </div>
                            )}
                        </Box>
                      )}

                    {values.applicantId !== '' &&
                      (createApplicationForm.status === 'succeed' ||
                        callesFrom === 'Documents') &&
                      values?.applicationId &&
                      values?.financial && (
                        <Box>
                          <Box maxW="max-content">
                            <Select
                              w="100%"
                              borderRadius="5px"
                              bg={'#114684'}
                              color={'white'}
                              textAlign={'center'}
                              onChange={handleChange}
                              value={formik?.values?.financialYear ?? ''}
                              name="financialYear"
                            >
                              <option
                                value={''}
                                style={{ color: 'black' }}
                                label="Select Option"
                              />
                              {formik.values.financial ===
                                'Key Personnel Resume' &&
                              callesFrom === 'Documents'
                                ? keypersonneldata?.map(
                                    (item: any, index: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={item.name}
                                        label={item.name}
                                      />
                                    )
                                  )
                                : formik?.values?.financial ===
                                      'Key Personnel Resume' &&
                                    callesFrom !== 'Documents'
                                  ? keydata?.map((item: any, index: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={item.name}
                                        label={item.name}
                                      />
                                    ))
                                  : formik?.values?.financial === 'Other' &&
                                      ((callesFrom === 'Documents' &&
                                        CreditBureauFormData?.paymentPlan) ||
                                        (callesFrom !== 'Documents' &&
                                          paymentPlan))
                                    ? OtherOptionWithtaxingEntity?.map(
                                        (item: any, index: number) => (
                                          <option
                                            style={{ color: 'black' }}
                                            key={index}
                                            value={item.value}
                                            label={item.label}
                                          />
                                        )
                                      )
                                    : getSecondDropDownOption(
                                        formik?.values?.financial
                                      )?.map((item: any, index: number) => (
                                        <option
                                          style={{ color: 'black' }}
                                          key={index}
                                          value={item.value}
                                          label={item.label}
                                        />
                                      ))}
                            </Select>
                          </Box>
                          {touched.financialYear && errors.financialYear && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.financialYear}
                            </div>
                          )}
                        </Box>
                      )}
                  </Flex>
                  <Box>
                    <Box mb={5}>
                      <Flex flexWrap={'wrap'} gap={3} alignItems={'flex-end'}>
                        <FileInput
                          icon={
                            <IconFileCv
                              style={{ width: 18, height: 18 }}
                              stroke={1.5}
                            />
                          }
                          styles={{
                            input: {
                              maxWidth: '350px',
                              border: '1px solid #114684',
                            },
                          }}
                          // @ts-ignore
                          placeholder="Upload files"
                          label="Upload files"
                          multiple={
                            formik.values.financial !== 'Financial Statement' &&
                            formik.values.financial !== 'Key Personnel Resume'
                          }
                          onChange={(payload: File[]) =>
                            handleFileInputUpload(payload)
                          }
                          value={
                            values.financialFileWithComment?.map(
                              (file) => file.file
                            ) ?? []
                          }
                          description="The file format should be - xlsx, xls, csv, pdf, doc, docx"
                          withAsterisk
                          variant="filled"
                          accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                        <Button
                          disabled={!values.financialFileWithComment?.length}
                          color="red"
                          onClick={(e) =>
                            setFieldValue('financialFileWithComment', [])
                          }
                          type="button"
                        >
                          Reset
                        </Button>
                      </Flex>
                      {touched.financialFileWithComment &&
                        errors.financialFileWithComment && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {errors.financialFileWithComment}
                          </div>
                        )}
                    </Box>
                    {values.financialFileWithComment &&
                      values.financialFileWithComment.length > 0 && (
                        <List
                          spacing="xs"
                          size="sm"
                          center
                          type="ordered"
                          mb={16}
                        >
                          {values.financialFileWithComment?.map(
                            (data, index) => (
                              <List.Item className="file-item">
                                <Box w={500}>
                                  <Flex gap={2} mb={1}>
                                    <Text>{data.file.name}</Text>
                                    <Tooltip
                                      label="Delete"
                                      placement={'top'}
                                      hasArrow
                                    >
                                      <Box>
                                        <CgTrash
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          className="file-item-trash"
                                          onClick={() => {
                                            const newData =
                                              values.financialFileWithComment?.filter(
                                                (_, i) => i !== index
                                              );

                                            setFieldValue(
                                              `financialFileWithComment`,
                                              newData
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                    <Tooltip
                                      label="Additional Info"
                                      placement={'top'}
                                      hasArrow
                                    >
                                      <Box>
                                        <CgComment
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          className="file-item-trash"
                                          onClick={() =>
                                            toggleCommentBox(
                                              index,
                                              data.comment!
                                            )
                                          }
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Flex>
                                  {data.comment !== null && (
                                    <Textarea
                                      placeholder="comment"
                                      label="Comment"
                                      autosize
                                      minRows={2}
                                      maxRows={4}
                                      value={data.comment ?? ''}
                                      onChange={(event) =>
                                        setFieldValue(
                                          `financialFileWithComment[${index}].comment`,
                                          event.currentTarget.value
                                        )
                                      }
                                      styles={{
                                        wrapper: {
                                          marginBottom: 16,
                                        },
                                      }}
                                    />
                                  )}
                                </Box>
                              </List.Item>
                            )
                          )}
                        </List>
                      )}
                  </Box>
                  <Box>
                    <ChakraButton
                      isLoading={S3UploadLoading}
                      type="submit"
                      bg="#114684"
                      color="white"
                      _hover={{ bg: '#114684', color: 'white' }}
                      // disabled={!(formik.isValid && formik.dirty)}
                    >
                      Submit Documents
                    </ChakraButton>
                  </Box>
                </Flex>
              </form>
            </Box>
          </>
        )}
        <Box px={5}>
          {zipName &&
            values.applicantId !== '' &&
            values.applicationId !== '' &&
            (createApplicationForm.status === 'succeed' ||
              callesFrom === 'Documents') && (
              <UploadFilesTable
                formState={formik?.values}
                isTableLoading={isTableLoading}
                zipName={zipName}
              />
            )}
        </Box>
      </Box>
    </>
  );
}

export default UploadDropdowns;
